import {ESinkType, SinkResponse, SinkTableResponse} from "../../../../data/sinks.data";
import {IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";

export default class SinkTablerowComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      sink: "="
    }
    this.template = require('./sink.tablerow.component.html');

    this.controller = SinkTablerowController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
require('./sink.tablerow.component.scss')

class SinkTablerowController {
  public $scope: IScope;
  public $uibModal;
  public restService: RestService;
  public hasSinkPriv: boolean = false;
  public sink: SinkTableResponse;

  constructor($scope: IScope, $uibModal, restService: RestService, public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.hasSinkPriv = this.privilegeService.has(RolePrivilege.Settings_Tracking_Sinks);


    this.$scope.$watch("ctrl.sink", (newValue: SinkTableResponse, oldValue) => {
      if (newValue) {
        this.sink = newValue;
      } else if (oldValue) {
        this.sink = oldValue;
      }
    })

  }

  edit() {
    if (!this.hasSinkPriv) {
      return;
    }
    this.restService.loadSink(this.sink.id).then((savedSink) => {
      switch (savedSink.sinkType) {
        case ESinkType.FE2_TO_FE2: {
          this.openModalForType('edit.fe2sink.modal/edit.fe2sink.modal.html', 'EditFe2sinkModal', savedSink);
          break
        }
        case ESinkType.GEO_BYTE: {
          this.openModalForType('edit.geobyteSink.modal/edit.geobyteSink.modal.html', 'EditGeobyteModal', savedSink);
          break
        }
        case ESinkType.BWB_OFFICE: {
          this.openModalForType('edit.bwbOffice.modal/edit.bwbOffice.modal.html', 'EditBwbModal', savedSink);
          break
        }
        case ESinkType.FIREBOARD: {
          this.openModalForType('edit.fireboardSink.modal/edit.fireboardSink.modal.html', 'EditFireboardSinkModal', savedSink);
        }
      }
    });
  }

  private openModalForType(htmlPath: string, controller: string, sink: SinkResponse) {
    this.$uibModal.open({
      template: require('../../../modals/sinks/' + htmlPath),
      controller: controller,
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        sink: () => {
          return sink;
        },
        isNew: () => {
          return false;
        }, okFunction: () => {
          return (refresh) => {
            if (refresh) {
              this.$scope.$emit("sink.changed");
            }
          }
        }
      }
    });
  }

  deleteSink() {
    if (!this.hasSinkPriv) {
      return;
    }
    this.restService.deleteSink(this.sink.id)
      .then(() => {
        this.$scope.$applyAsync();
      }).finally(() => {
      this.$scope.$emit("sink.changed");
    });
  }

  changeState() {
    if (!this.hasSinkPriv) {
      return;
    }
    this.restService.changeSinksActiveState(this.sink.id).then((stateResponse) => {
      this.sink.active = stateResponse.active;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

}
