'use strict';

import {UserAdminContext} from "../../../../data/admin.data";
import AdminService from "../../../../services/admin.service";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import RestService from "../../../../services/rest.service";
import * as angular from "angular";

require('./admin.users.component.scss');

export default class AdminUsersComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./admin.users.component.html');
    this.scope = {
    };
    this.controller = AdminUsersComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

const DAY_RANGES_LOCAL_STORAGE_KEY = "adminAlarmDeletionDayRanges";

/* @ngInject */
class AdminUsersComponentController {
  public $scope: angular.IScope;
  public $rootScope: angular.IRootScopeService;
  public dataService: any;
  public adminService: AdminService;
  public $state: any;
  public $log: angular.ILogService;
  public $uibModal: any;
  public $translate: any;
  public Notification: any;
  public deleteDays: number[] = [0, 7, 14, 30, 60, 90, 180];
  public selectedDayRange: Map<string, number> = new Map<string, number>();
  public numberOfAlarms: number;
  public loading: Map<string, boolean> = new Map<string, boolean>();
  public FileUploader: any;
  public defaultImport: AdminOrgaUnitImport;
  public restService: RestService;
  public $http: angular.IHttpService;
  public $window:angular.IWindowService;
  public isLoading: boolean = false;
  public users: UserAdminContext[] = [];
  public hasAlarmDeletePriv: boolean;

  constructor($scope: angular.IScope, $rootScope: angular.IRootScopeService, $translate, adminService: AdminService, $log: angular.ILogService,
    public privilegeService: PrivilegeService, $uibModal, $state, Notification, dataService, FileUploader, restService:RestService, $http: angular.IHttpService, $window: angular.IWindowService, private helperService: HelperService) {
    this.adminService = adminService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.dataService = dataService;
    this.$state = $state;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$translate = $translate;
    this.Notification = Notification;
    this.FileUploader = FileUploader;
    this.restService = restService;
    this.$http= $http;
    this.$window= $window;
    this.determineDefault();
    this.load();
    this.hasAlarmDeletePriv = this.privilegeService.has(RolePrivilege.Admin_Users_Delete_Alarms);
  }
  addOrganisation(){
    this.$uibModal.open({
      template: require('../../../modals/admin/add.organisation.modal/add.organisation.modal.html'),
      controller: 'AddOrganisationModalController',
      controllerAs: 'ctrl',
      size: 'md',
      backdrop: 'static',
      resolve:{
        existingUsers:()=>{
          return this.users;
        },
        okFunction: ()=>{
          return ()=>{
            this.isLoading = true;
            //reload necessary because of not all GUI data available in admin service (dataService-> nbr of alarms)
            this.load();
            this.$scope.$applyAsync();
            this.isLoading = false;

              }
          }
        }
    })

  }

  determineDefault(){
    if (this.privilegeService.has(RolePrivilege.Admin_Users_Create)){
      this.defaultImport = AdminOrgaUnitImport.Organisation;
    }else {
      // units can be edited/ created in Orgas with only Admin_Users Privilege
      this.defaultImport = AdminOrgaUnitImport.Units;
    }
  }

  import(){
    if (this.defaultImport === AdminOrgaUnitImport.Organisation){
      this.importOrganisations();
    } else {
      this.importUnits();
    }

  }

  importOrganisations(){
      var modalInstance = this.$uibModal.open({
        template: require('../../../modals/admin/import.organisation.modal/import.organisation.modal.html'),
        controller: 'ImportOrganisationModalController',
        controllerAs: 'ctrl',
        size: 'sm',
        resolve: {
          uploader: () => {
            let fileUploader = new this.FileUploader();
            return fileUploader
          }
        }
      });
      modalInstance.result.then(() =>{
        this.load();
        this.$scope.$applyAsync();
      });

  }
  /**
   * Delete alarm histroy
   */
  delete(user: UserAdminContext) {
    if (!this.hasAlarmDeletePriv) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/admin/confirm.clear.database.modal/confirm.clear.database.modal.html'),
      controller: 'ConfirmClearDatabaseModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        selectedDayRange: () => {
          return 'ADMIN.ALARM_DELETE_DAYS.' + this.selectedDayRange[user.id];
        },
        okFunction: () => {
          return () => {
            this.loading[user.id] = true;

            this.dataService.deleteAlarms(user.name, this.selectedDayRange[user.id],
              (response) => {
                this.$translate(['ADMIN.HISTORY_DELETED_TITLE']).then((translations) => {
                  this.Notification.success({
                    message: response.message,
                    title: translations['ADMIN.HISTORY_DELETED_TITLE']
                  });
                  this.getAlarmCount();
                });
                this.loading[user.id] = false;
              },
              (err) => {
                this.$log.error('Could not delete alarm histroy ');
                this.$log.error(err);
                this.getAlarmCount();
                this.loading[user.id] = false;
              });
          };
        }
      }
    });
  };

  getAlarmCount() {
    this.dataService.getNumberOfAlarmsForAllUsers(data => {
      this.numberOfAlarms = data;
    }, error => {
      this.$log.error(error);
    })
  }

  /**
   * Login as specific user
   * @param username
   */
  login(user: UserAdminContext) {
    this.dataService.changeLoginAsAdmin(user.name,
      () => {
        this.$log.debug('Logged in as user: ' + user.name);
        this.$state.go(this.getOverviewPath());
      },
      () => {
        this.$log.error('Could not login as user: ' + user.name);
      });
  };

  /**
   * Reset 2FA
   * @param user
   */
  reset2FA(user: UserAdminContext) {
    this.loading.set(user.id, true);
    this.adminService.reset2FA(user).finally(() => {
      this.loading.set(user.id, false);
      user.has2FA = false;
      this.$scope.$applyAsync();
    });
  }

  deleteUser(user:UserAdminContext){
    this.$uibModal.open({
      template: require('../../../modals/admin/save.delete.organisation.modal/save.delete.organisation.modal.html'),
      controller: 'SaveDeleteOrganisationModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        hasOnlineService: ()=> {
          return user.hasOnlineService;
        },
        user: () => {
          return user;
        },
        okFunction: () => {
          return (deleteOS: boolean) => {
            this.loading.set(user.id, true);
            this.adminService.deleteOrganisation(user, deleteOS).then(success => {
              this.users.splice(this.users.indexOf(user),1)

            }).finally(()=>{
              this.$scope.$applyAsync();
            });
          }
        }
      }

    });

  }

  isUserLoading(user: UserAdminContext) {
    return this.loading.get(user.id);
  }

  /**
   * Open selected user
   * @param user
   */
  openUser(user: UserAdminContext) {
    let modalInstance = this.$uibModal.open({
      template: require('../../../modals/admin/user.modal/user.modal.html'),
      controller: 'UserModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        userContext: () => {
          return user;
        },
        userNames:()=>{
          return this.users;
        },
        okFunction: ()=>{
          return (needsUserListReload : boolean) =>{
            if (needsUserListReload){
              this.isLoading = true;
              //reload users after renaming
              this.load();
              this.isLoading = false;
              this.$scope.$applyAsync();
            }
          }
        }
      }
    });
    modalInstance.result.then(() => this.load())
  }

  /**
   * Load all users
   */
  load() {
    this.isLoading = true;
    const persistedDayRanges = JSON.parse(this.helperService.getFromStorage(DAY_RANGES_LOCAL_STORAGE_KEY, "{}"));
    this.adminService.getAllUsers().then(users => {
      this.users = users;
      this.users.forEach(user => {
        this.loading.set(user.id, false);
        this.selectedDayRange[user.id] = persistedDayRanges[user.id] ?? 0;
      });
    }).catch(err => {
      this.$log.error(err);
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });

    this.getAlarmCount();
  };

  unblock(user:UserAdminContext){
    this.restService.unblockUser(user.id).then(r => {
      //reload users after unblock
      this.load();
      this.$scope.$applyAsync();
    });
  }

  changeDeleteDays(value: number, user: UserAdminContext) {
    this.selectedDayRange[user.id] = value;
    this.helperService.saveInStorage(DAY_RANGES_LOCAL_STORAGE_KEY, JSON.stringify(this.selectedDayRange));
  }

  private getOverviewPath(): string {
    return this.helperService.getFromStorage("overviewPath", "main.home");
  }

  changeImportType(type){
    if (type === AdminOrgaUnitImport.Organisation) {
      this.importOrganisations();
    } else {
      this.importUnits();
    }
  }

  importUnits() {
    this.$uibModal.open({
      template: require('../../../modals/admin/import.units.modal/import.units.modal.html'),
      controller: 'ImportUnitsModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader;
        }
      }
    });
  }
  exportLicence(){
    this.$window.open(
      this.restService.getBaseUrl() + '/admin/users/licences/download?Authorization='+
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  }

  exportUnits() {
    this.$window.open(
      this.restService.getBaseUrl() + '/admin/units/export/csv?Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  }

}
enum AdminOrgaUnitImport{
  Organisation='Organisation',
  Units = 'Units'
}
