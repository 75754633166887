import {Emergency} from "../../../../../data/emergency.data";
import RestService from "../../../../../services/rest.service";
import {
  ESelectiveAlarmStatus,
  PersonSelected,
  SelectiveAlarm,
  SelectiveAlarmNotificationRequest
} from "../../../../../data/selective.alarms.data";
import {PersonFeedbackResponse} from "../../../../../data/feedback.data";
import PrivilegeService from "../../../../../services/privilege.service";
import {RolePrivilege} from "../../../../../data/privileges.enum";
import { EFeedbackState } from "../../../../../data/customFeedback.data";
import { IScope } from "angular";

require("./selective.alam.component.scss")
/* @ngInject */
export default class SelectiveAlamComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./selective.alam.component.html');
    this.scope = {
      emergency: '=',
      selective: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = SelectiveAlamComponentController;

  }
}

class SelectiveAlamComponentController {
  public emergency: Emergency;
  public selective: string;
  public restService: RestService;
  public selectiveAlarm: SelectiveAlarm;
  public isLoading: boolean;
  public hasFeedback: boolean = false;
  public $scope: any;
  public feedback: PersonFeedbackResponse[] = [];
  public positives: PersonSelected[] = [];
  public showCustomText: Map<string, boolean> = new Map<string, boolean>;
  public acceptText: string;
  public rejectText: string;
  public hasEditRight: boolean;
  private refreshTimer;


  constructor(restService: RestService, $scope: IScope, public privilegeService: PrivilegeService, private Notification, private $translate) {
    this.restService = restService;
    this.$scope = $scope;
    this.hasEditRight = this.privilegeService.has(RolePrivilege.Home_Selective_Alarm_Edit);

    $scope.$watch('ctrl.selective', (oldValue, selectiveId: string) => {
      if (selectiveId) {
        this.selective = selectiveId;
        this.loadSelectiveAlarmData();
      }
    });
    this.$scope.$on('$destroy', () => {
      //cancel
      clearInterval(this.refreshTimer);
    });

  }

  /**
   * If at least one person is selected, it returns true, if this person is not selected
   * @param personId 
   * @returns 
   */
  activateNotSelected(personId: string) {
    if (this.positives.length === 0) {
      return false;
    }
    return !this.personSelected(personId);
  }


  personSelected(personId: string): boolean {
    if (personId === null) {
      return false;
    }
    if (!this.isEditableById(personId)) {
      return true;
    }
    return this.positives.findIndex(positive => positive.personId === personId) > -1;
  }

  toggleCustomText(entry: PersonFeedbackResponse) {
    if (!this.isEditable(entry)) {
      return;
    }
    if (this.personSelected(entry.personId)) {
      if (this.showCustomText.has(entry.personId)) {
        let flag = this.showCustomText.get(entry.personId);
        this.showCustomText.set(entry.personId, !flag);
      } else {
        this.showCustomText.set(entry.personId, true);
      }
      this.$scope.$applyAsync();
    }
  }

  hasDifferentTextThanDefault(entry: PersonFeedbackResponse) {
    let person = this.getSelectedEntry(entry);
    if (person) {
      let text = person.text
      if (!text || text === '') {
        return false;
      }
      return text !== this.acceptText;
    }
    return false;
  }

  getSelectedEntry(entry: PersonFeedbackResponse): PersonSelected {
    return this.positives.find(positive => positive.personId === entry.personId);
  }

  showCustomTextField(entry: PersonFeedbackResponse) {
    if (this.personSelected(entry.personId)) {
      if (this.showCustomText.has(entry.personId)) {
        return this.showCustomText.get(entry.personId);
      }
    }
    return false;
  }

  selectPerson(personFeedback: PersonFeedbackResponse) {
    if (!this.hasEditRight) {
      return;
    }
    if (this.personSelected(personFeedback.personId)) {
      this.positives.splice(this.positives.findIndex(person => person.personId == personFeedback.personId), 1);
      return;
    }
    let person = {personId: personFeedback.personId} as PersonSelected;
    this.positives.push(person);
  }

  private loadSelectiveAlarmData() {
    this.isLoading = true;
    this.restService.getSelectiveAlarms(this.selective).then((selectiveAlarm) => {
      this.selectiveAlarm = selectiveAlarm;
    }).then(() => {
      this.loadFeedback();
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
      this.refreshTimer = setInterval(() => {
        // Refreshes the layer
        this.loadFeedback();
      }, 1000 * 5);
    })
  }

  loadFeedback() {
    this.restService.loadFeedbackSelectiveAlarm(this.selectiveAlarm.userId, this.selectiveAlarm).then(result => {
      this.feedback = result.sort((a, b) => a.name.localeCompare(b.name));
      this.hasFeedback = true;
      this.$scope.$applyAsync();
    });
  }

  hasStandardTexts(): boolean {
    if (this.acceptText && this.acceptText !== '') {
      if (this.rejectText && this.rejectText !== '') {
        return true;
      }
    }
    return false;
  }

  isEditableById(personId: string) {
    if (this.selectiveAlarm.personsSelected) {
      if (this.selectiveAlarm.personsSelected.findIndex(selected => selected.personId === personId) > -1) {
        return false;
      }
    }
    return true;
  }

  isEditable(entry: PersonFeedbackResponse) {
    return this.isEditableById(entry.personId);
  }

  sendInfoAlarm() {
    if (!this.hasEditRight) {
      return;
    }
    this.isLoading = true;
    let listDenied = [];
    this.feedback.forEach(entry => {
      if (!this.personSelected(entry.personId)) {
        let deniedRequest = {personId: entry.personId, text: this.rejectText} as PersonSelected;
        listDenied.push(deniedRequest);
      }
    });
    this.positives.forEach(positive => {
      if (!positive.text || positive.text === '') {
        positive.text = this.acceptText;
      }
    });
    let request = {
      id: this.selectiveAlarm.id,
      selectedList: this.positives,
      deniedList: listDenied
    } as SelectiveAlarmNotificationRequest;

    this.restService.sendSelectiveAlarmInfo(request).then((updated) => {
      this.selectiveAlarm = updated;
      this.positives = [];
      this.$translate('MISSIONS.SELECTIVE_ALARM_TAB.SEND_SUCCESS').then((translation) => {
        this.Notification.success({
          message: translation
        });
      });
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  customText(entry: PersonFeedbackResponse) {
    let person = this.getSelectedEntry(entry);
    if (person) {
      let text = person.text
      if (text && text !== '') {
        return text;
      }
    }
    // if text not defined check if status of selective alarm is done
    // proceed to check if person was already informed
    if (this.selectiveAlarm.status === ESelectiveAlarmStatus.DONE) {
      person = this.selectiveAlarm.personsSelected.find(person => person.personId === entry.personId);
      if (person && person.text && person.text !== '') {
        return person.text;
      }
    }
    if (this.acceptText && this.acceptText !== '' && person) {
      return this.acceptText;
    }
    return
  }
}
