import {IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {SinkResponse} from "../../../../data/sinks.data";

export default class EditBwbModal {
  public $uibModalInstance: any;
  public $scope: IScope;
  public restService: RestService;
  public okFunction: any;
  public bwbSink: SinkResponse;
  public isNew: boolean;
  public isSaving: boolean = false;

  public bwbModel = {
    url: "",
    auth: "",
    password: ""
  };


  constructor($uibModalInstance: any, $scope: angular.IScope, restService: RestService, sink: SinkResponse, isNew: boolean, okFunction: any) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.restService = restService;
    this.okFunction = okFunction;
    this.bwbSink = sink;

    this.bwbModel.url = this.bwbSink.data[ParameterBWBOffice.baseUrl];
    this.bwbModel.auth = this.bwbSink.data[ParameterBWBOffice.authorisation];
    this.bwbModel.password = this.bwbSink.data[ParameterBWBOffice.password];


  }

  cancel() {
    // no reload if no change was made
    this.okFunction(false);
    this.$uibModalInstance.close();
  }

  save() {
    this.isSaving = true;
    this.bwbSink.data[ParameterBWBOffice.baseUrl] = this.bwbModel.url;
    this.bwbSink.data[ParameterBWBOffice.authorisation] = this.bwbModel.auth;
    this.bwbSink.data[ParameterBWBOffice.password] = this.bwbModel.password;
    this.restService.saveSink(this.bwbSink).then(() => {
      this.isSaving = false;
    }).finally(() => {
      // reload table on save
      this.okFunction(true);
      this.$uibModalInstance.close();

    });
  }
}

export enum ParameterBWBOffice {
  baseUrl = 'baseUrl',
  authorisation = 'authorisation',
  password = 'password'
}