'use strict';

import {ILogService, IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {IOprintJobInfoResponsePage, IOPrintsInfoResponse, IOprintUpdateRequest} from "../../../../data/ioprint.data";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./ioprint.update.modal.scss');


/* @ngInject */
export default class IOprintUpdateModalController {
  public $scope: IScope;
  public $uibModal;
  public $log: ILogService;
  public $uibModalInstance: any;
  public restService: RestService;
  public isLoading: boolean = false;
  public limit: number = 20;
  public ioprintsInfo: IOPrintsInfoResponse[];
  public selectedIds: string[];
  public blockedIds: string[];
  public data: IOprintJobInfoResponsePage;
  public okFunction: any;
  public code: string;


  constructor($scope: IScope, $log: ILogService, $uibModal, $uibModalInstance, restService: RestService, ioprintsInfo: IOPrintsInfoResponse[], selectedIds: string[], okFunction: Function) {
    this.$scope = $scope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.ioprintsInfo = ioprintsInfo;
    this.selectedIds = selectedIds;
    this.okFunction = okFunction;
    this.code = '';
   // this.okFunction = okFunction;
    console.log('this.ioprintsInfo' + this.ioprintsInfo)
    this.blockedIds = ioprintsInfo.filter(x => this.isUpdationBlocked(x)).map(x => x.id);
    this.selectedIds = this.selectedIds.filter(x => !this.blockedIds.includes(x));
  }

  toggleIoPrint(id: string){
    if(this.selectedIds.includes(id)){
      this.selectedIds = this.selectedIds.filter(x=>x !=id);
    }else{
      if(!this.blockedIds.includes(id)){
        this.selectedIds.push(id);
      }
    }
  }

  public isUpdationBlocked(ioPrint: IOPrintsInfoResponse){
    return ioPrint.updateBlockingTimeUntil &&
      ioPrint.updateBlockingTimeUntil >= (new Date()).getTime();
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  update() {
    this.okFunction(this.selectedIds, this.code);
    this.$uibModalInstance.close();
  }


}
