'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import {RolePrivilege} from "../../../../data/privileges.enum";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import AccountService from "../../../../services/account.service";
import {EFE2Features} from "../../../../data/account.data";
import {ERoadWorksType} from "../../../../data/roadblock.data";

require('./roadblock.tablerow.component.css');

//roadblockRow
export default class RoadblockRowComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./roadblock.tablerow.component.html');
    this.scope = {
      roadblock: '=',
      username: '=',
      deleting: '='
    };
    this.controller = RoadblockRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class RoadblockRowComponentController {
  public state = 'PLANNED';
  public isShiftBookActive = false;
  public roadblock: any;
  public roadworkTypes: typeof ERoadWorksType= ERoadWorksType;

  constructor(
    public $rootScope: IRootScopeService,
    public $scope: IScope,
    public $log: ILogService,
    public $uibModal,
    public dataService,
    public helperService: HelperService,
    public privilegeService: PrivilegeService,
    accountService: AccountService) {

    this.$scope.$watch('ctrl.roadblock.from', () => {
      // Type change
      this.updateState();
    });
    this.$scope.$watch('ctrl.roadblock.to', () => {
      // Type change
      this.updateState();
    });

    this.isShiftBookActive = accountService.isFeatureEnabled(EFE2Features.SHIFT_BOOK);
  }

  /**
   * Update current state
   */
  updateState() {
    this.state = this.helperService.getRoadblockState(this.roadblock);
  };

  /**
   * Delete
   */
  deleteRoadblock($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.dataService.deleteRoadblockById(this.roadblock, () => {
              this.$rootScope.$emit('roadblock.deleted', this.roadblock);
            }, (response) => {
              this.$log.error(response);
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  };


  /**
   * Toggle the important flag
   */
  toogleImportantState($event) {
    $event.stopPropagation();
    $event.preventDefault();

    if (!this.privilegeService.has(RolePrivilege.Objects_Roadblocks_Edit)) {
      return;
    }

    this.roadblock.important = !this.roadblock.important;
    this.dataService.updateRoadblock(this.roadblock, false, () => {
      // Success
      this.$log.info('Important flag updated');
    }, (error) => {
      // Error updating state
      this.$log.error(error);
    });
  };
}
