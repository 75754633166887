'use strict';

import { IScope } from "angular";
import RestService from "../../../../services/rest.service";
import { DryadDeviceResponse } from "../../../../data/admin.data";
import { InputPluginDetails } from "../../../../data/input.data";
import { server } from "typescript";

require('./dryad.devices.base.field.scss');

export default class DryadDevicesBaseFieldComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./dryad.devices.base.field.html');
    this.scope = {
      input: '='
    };
    this.controller = DryadDevicesBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class DryadDevicesBaseFieldController {
  public input: InputPluginDetails;
  public restService: RestService;
  public devices: DryadDeviceResponse[] = [];
  public $scope: IScope;
  public isLoading = false;

  constructor(restService: RestService, $scope: IScope) {
    this.restService = restService;
    this.$scope = $scope;
    

    this.$scope.$watch("ctrl.input", (input) => {
      console.error('watch)');
      if (input) {
        this.load();
      }
    });
  }

  deleteSensor(sensorId: string) {
    this.isLoading = true;
      this.restService.deleteDryadDevice(this.input.id, sensorId)
        .then((result: DryadDeviceResponse[]) => {
          this.devices = result;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
        });
  }


  load() {
    return new Promise<void>((resolve) => {
      this.isLoading = true;
      this.restService.loadDryadDevices(this.input.id)
        .then((result: DryadDeviceResponse[]) => {
          this.devices = result;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }
}
