'use strict';

import angular = require("angular");

require('./alarm.feedback.component.scss');


export default class AlarmFeedbackComponent {
  public restrict: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;
  public scope: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./alarm.feedback.component.html');
    this.controller = AlarmFeedbackComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.scope = {
      alarm: '=',
      feedback: '='
    };
  }
}

/* @ngInject */
class AlarmFeedbackComponentController {
  public hasFeedback = false;
  public feedback: any;

  constructor($scope: angular.IScope) {
    $scope.$watch('ctrl.feedback', (oldValue, newValue) => {
      if (newValue) {
        this.hasFeedback = angular.isDefined(this.feedback) && this.feedback.lstOfFeedbacks.length > 0;
      }
    });
  }

  /**
   * Splitted einen String in mehrere Teile (Trennzeichen: ;)
   * */
  getSplittedFunctions(toSplit: string) {
    if (toSplit === undefined) {
      return [];
    }

    toSplit = toSplit.replace(/,/g, ';');
    return toSplit.split(';');
  };
}
