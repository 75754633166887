'use strict';

import {IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {ELogicalSectionTypes, SectionsResponse, VehicleAssignment} from "../../../../data/sections.templates.data";
import {Vehicle, VehiclesPaginatedResponse} from "../../../../data/vehicles.data";
import {SortParams} from "../../../views/addressbook.view.component/addressbook.view.component";
import angular = require("angular");

require('./sections.editor.scss');

/* @ngInject */
export default class SectionsEditorController {
  public $scope: IScope;
  public $uibModal;
  public $uibModalInstance: any;
  public restService: RestService;
  public isLoading: boolean = false;
  public templateId: string;
  public okFunction;
  public section: SectionsResponse;
  public logicalTypes: typeof ELogicalSectionTypes = ELogicalSectionTypes;
  public mode: string;
  public logicalTypeNeedsRadio2 = false;
  public isNew: boolean = false;

  public vehicles: Vehicle[] = [];
  public selectedVehicles: VehicleAssignment[] = [];
  public isLoadingVehicles: boolean;
  public isSaving = false;
  //parameters for all vehicles
  public params = {
    sortType: 'name', // set the default sort type
    sortReverse: false, // set the default sort order
    searchFilter: '', // set the default search/filter term
    currentPage: 0,
    totalElements: 0,
    pageSize: 15
  } as SortParams;


  constructor($scope: IScope, $uibModalInstance, restService: RestService, templateId: string, nbr: string, okFunction, section: SectionsResponse) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.templateId = templateId;
    this.okFunction = okFunction;
    if (section) {
      this.section = section;
      this.logicalTypeNeedsRadio2 = this.section.logicalType !== ELogicalSectionTypes.DEFAULT && this.section.logicalType !== ELogicalSectionTypes.COMMAND;
    } else {
      this.isNew = true;
      this.section = {
        nbr: nbr,
        templateId: templateId,
        logicalType: ELogicalSectionTypes.DEFAULT
      } as SectionsResponse;
      this.logicalTypeNeedsRadio2 = false;
    }
    this.mode = 'MAIN';
    this.selectedVehicles = this.section.assignee
    this.loadVehicles();
  }

  save() {
    if (!this.section.name) {
      return;
    }
    if (this.section.logicalType === ELogicalSectionTypes.DEFAULT || this.section.logicalType === ELogicalSectionTypes.COMMAND) {
      this.section.radio2 = '';
    }
    if (this.section.logicalType !== ELogicalSectionTypes.DEFAULT) {
      this.section.nbr = '';
    }
    this.okFunction(this.section);
    this.$uibModalInstance.close();
  }

  cancel() {
    this.$uibModalInstance.close();
  }


  selectLogicalType(logicalType: ELogicalSectionTypes) {
    if (this.section) {
      this.section.logicalType = logicalType;
      if (logicalType === ELogicalSectionTypes.DEFAULT || logicalType === ELogicalSectionTypes.COMMAND) {
        this.logicalTypeNeedsRadio2 = false;
      } else {
        this.logicalTypeNeedsRadio2 = true;
      }
    }
  }


  /**
   * load all vehicles paginated from backend
   */
  loadVehicles() {
    this.isLoadingVehicles = true;
    this.restService.loadVehiclesPaginatedAsPromise(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1,
      this.params.pageSize,
      this.params.searchFilter,
      this.params.sortReverse ? 'DESC' : 'ASC').then((result: VehiclesPaginatedResponse) => {
      if (angular.isDefined(result.data)) {
        this.vehicles = result.data;
      }
      this.params.totalElements = result.totalElements;
      this.params.totalPages = result.totalPages;
    }).finally(() => {
      this.isLoadingVehicles = false;
      this.$scope.$applyAsync();
    });

  }

  /**
   * make Http call to add a vehicle to an organisation.
   * @param vehicle
   */
  saveVehiclesAccess(vehicle: Vehicle) {
    let va = {
      vehicleId: vehicle.id,
      timestamp: 0,
      vehicleName: vehicle.name,
      vehicleShortname: vehicle.shortName,
      strength: vehicle.currentStrength
    }
    if (!this.section.assignee) {
      this.section.assignee = [];
    }
    if (!this.vehicleIsSelectedInOrganisation(vehicle)) {
      this.section.assignee.push(va);
      this.$scope.$applyAsync();
    }

  }

  removeSelected(vehicle: VehicleAssignment) {
    const foundVehicle = this.section.assignee.filter(vehicleL => vehicleL.vehicleId === vehicle.vehicleId);

    if (foundVehicle.length > 0) {
      this.section.assignee.splice(this.section.assignee.indexOf(foundVehicle[0]), 1);
    }

    this.$scope.$applyAsync();

  }

  removeAllVehicles() {
    this.section.assignee = [];
    this.$scope.$applyAsync();

  }

  /**
   * add all vehicles currently displayed in all vehicles list
   */
  addAllVisible() {
    this.vehicles.forEach(vehicle => this.saveVehiclesAccess(vehicle));
    this.$scope.$applyAsync();
  }

  /**
   * check if access is already set for a vehicle
   * @param vehicle
   */
  vehicleIsSelectedInOrganisation(vehicle: Vehicle) {
    if (angular.isUndefined(this.section.assignee)) {
      return;
    }
    var list = this.section.assignee.filter(vehicleL => vehicleL.vehicleId === vehicle.id);
    return list.length === 1;
  }

  /**
   * search in all vehicles colum
   */
  search() {
    this.params.currentPage = 0;
    this.loadVehicles();
  }

  /**
   * reset search in all vehicles colum
   */
  resetSearchAndReload() {
    this.params.searchFilter = '';
    this.search();
  }
}
