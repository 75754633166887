import de from './assets/translations/locale_de.json'
import en from './assets/translations/locale_en.json'

/* @ngInject */
export default function routing($rootScopeProvider, $stateProvider, $animateProvider, $uibModalProvider, $logProvider, $sceDelegateProvider, $translateProvider, $urlRouterProvider, localStorageServiceProvider) {
  $urlRouterProvider.otherwise('/main/login');
  localStorageServiceProvider.setPrefix('alamos.fe2.');

  $translateProvider.useSanitizeValueStrategy('sceParameters');
  $translateProvider.translations('de', de);
  $translateProvider.translations('en', en);


  const selectedLanguage = localStorage.getItem('alamos.fe2.language');
  if (selectedLanguage && selectedLanguage !== null) {
    // Will be persisted as "end". Substring removes the ""
    $translateProvider.preferredLanguage(selectedLanguage.substring(1, 3));
  } else {
    $translateProvider.preferredLanguage('de');
  }


  var whitelist = $sceDelegateProvider.resourceUrlWhitelist();
  whitelist.push('https://**apager-firemergency-2.appspot.com/**');
  whitelist.push('https://**alamos-backend.ey.r.appspot.com/**');
  $sceDelegateProvider.resourceUrlWhitelist(whitelist);

  $logProvider.debugEnabled(IS_DEBUG);

  // Dont close modals with escape
  $uibModalProvider.options.keyboard = false;

  //FontAwesome Problem
  $animateProvider.classNameFilter(/^((?!(fa-spinner)).)*$/);

  // Change max child size in pipeline tree
  $rootScopeProvider.digestTtl(30); // Change 30 to maximum no. of child you may have.

  $stateProvider
    .state('main', {
      url: '/main',
      template: require('./components/views/main.view.component/main.view.component.html'),
      controller: 'MainController',
      controllerAs: 'main'
    })
    .state('main.home', {
      url: '/home',
      template: require('./components/views/home.view.component/home.view.component.html'),
      controller: 'HomeController',
      controllerAs: 'home'
    })
    .state('main.assignments', {
      url: '/assignments',
      template: require('./components/views/assignments.view.component/assignments.view.component.html'),
      controller: 'AssignmentsController',
      controllerAs: 'ctrl'
    })
    .state('main.missions', {
      url: '/missions',
      template: require('./components/views/missions.view.component/missions.view.component.html'),
      controller: 'MissionsController',
      controllerAs: 'ctrl'
    })
    .state('main.queues', {
      url: '/queues',
      template: require('./components/views/queues.view.component/queues.view.component.html'),
      controller: 'QueuesController',
      controllerAs: 'ctrl'
    })
    .state('main.map', {
      url: '/map',
      template: require('./components/views/map.view.component/map.view.component.html'),
      controller: 'QueueMapController',
      controllerAs: 'ctrl'
    })
    .state('main.login', {
      url: '/login',
      template: require('./components/views/login.view.component/login.view.component.html'),
      controller: 'LoginController',
      controllerAs: 'login'
    })
    .state('main.users', {
      url: '/users',
      template: require('./components/views/addressbook.view.component/addressbook.view.component.html'),
      controller: 'AddressbookController',
      controllerAs: 'ctrl'
    })
    .state('main.templates', {
      url: '/templates',
      template: require('./components/views/templates.view.component/templates.view.component.html'),
      controller: 'TemplatesController',
      controllerAs: 'ctrl'
    })
    .state('main.admin', {
      url: '/admin',
      template: require('./components/views/admin.view.component/admin.view.component.html'),
      controller: 'AdminController',
      controllerAs: 'ctrl'
    })
    .state('main.alarm', {
      url: '/alarm/?code',
      template: require('./components/views/alarm.view.component/alarm.view.component.html'),
      controller: 'AlarmController',
      controllerAs: 'ctrl'
    })
    .state('main.wache', {
      url: '/wache/',
      template: require('./components/views/wache.view.component/wache.view.component.html'),
      controller: 'WacheController',
      controllerAs: 'ctrl'
    })
    .state('main.availability', {
      url: '/availability/',
      template: require('./components/views/availability.view.component/availability.view.component.html'),
      controller: 'AvailabilityController',
      controllerAs: 'ctrl'
    })
    .state('main.statistic', {
      url: '/statistic',
      template: require('./components/views/statistic.view.component/statistic.view.component.html'),
      controller: 'StatisticsController',
      controllerAs: 'ctrl'
    })
    .state('main.calendar', {
      url: '/calendar',
      template: require('./components/views/calendar.view.component/calendar.view.component.html'),
      controller: 'CalendarController',
      controllerAs: 'ctrl'
    })
    .state('main.units', {
      url: '/units/?code',
      template: require('./components/views/units.view.component/units.view.component.html'),
      controller: 'UnitsController',
      controllerAs: 'ctrl'
    })
    .state('main.objects', {
      url: '/objects',
      template: require('./components/views/objects.view.component/objects.view.component.html'),
      controller: 'ObjectsController',
      controllerAs: 'ctrl'
    })
    .state('main.leaderboard', {
      url: '/leaderboard',
      template: require('./components/views/leaderboard.view.component/leaderboard.view.component.html'),
      controller: 'LeaderboardController',
      controllerAs: 'ctrl'
    })
    .state('external', {
      url: '/external',
      template: require('./components/views/external.view.component/external.view.component.html'),
      controller: 'ExternalViewController',
      controllerAs: 'ctrl'
    })
    .state('external.register', {
      url: '/register',
      template: require('./components/views/external.registration.view.component/external.registration.view.component.html'),
      controller: 'ExternalRegistrationController',
      controllerAs: 'ctrl'
    })
    .state('external.persons', {
      url: '/persons',
      template: require('./components/views/external.persons.view.component/external.persons.view.component.html'),
      controller: 'ExternalPersonsController',
      controllerAs: 'ctrl'
    })
    .state('external.object', {
      url: '/objects',
      template: require('./components/views/external.object.view.component/external.object.view.component.html'),
      controller: 'ExternalObjectController',
      controllerAs: 'ctrl'
    });
}
