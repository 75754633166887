export enum ESelectiveAlarmStatus {
  OPEN = 'OPEN', DONE = 'DONE', CLOSED = 'CLOSED'
}

export interface SelectiveAlarmTableEntry {
  unitId: string;
  userId: string;
  externalId: string;
  timestamp: number;
  id: string;
  status: ESelectiveAlarmStatus;
  unitName: string;
  keyword: string;
  location_dest: string;
}

export interface SelectiveAlarm {
  id: string;
  unitId: string;
  userId: string;
  externalId: string;
  timestamp: number;
  lastUpdatedTimestamp: number;
  status: ESelectiveAlarmStatus;
  feedbackId: string;
  feedbackSharedSecret: string;
  personsSelected: PersonSelected[];
}

export interface PersonSelected {
  personId: string;
  text: string;
}

export interface SelectiveAlarmNotificationRequest {
  id: string;
  selectedList: PersonSelected[];
  deniedList: PersonSelected[];
}

export interface SelectiveAlarmStateChange {
  id: string;
  status: ESelectiveAlarmStatus;
  updateTime: number;
}