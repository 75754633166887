import {SinksResponsePaginated, ESinkType, SinkResponse} from "../../../../data/sinks.data";
import RestService from "../../../../services/rest.service";
import {IScope} from "angular";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";

export default class SinkSettingsComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.scope = {
      settings: "="
    }
    this.template = require('./sink.settings.component.html');

    this.controller = SinkSettingsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}
require('./sink.settings.component.scss')

class SinkSettingsController {
  public sinks: SinksResponsePaginated;
  public type: typeof ESinkType = ESinkType;
  public restService: RestService;
  public $scope: IScope;
  public $uibModal;
  public typeSelected;
  public currentPage: number = 0;
  public hasSinkPriv: boolean = false;

  constructor(restService: RestService, $scope: IScope, $uibModal, public privilegeService: PrivilegeService) {
    this.restService = restService;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.hasSinkPriv = this.privilegeService.has(RolePrivilege.Settings_Tracking_Sinks);
    // set this as default selected
    this.typeSelected = ESinkType.FE2_TO_FE2;
    this.loadSinks();

    this.$scope.$on("sink.changed", () => {
      this.reloadOnSinksChange();
    })
  }

  getIcon(type) {
    let iconCss = 'icon-'
    switch (type) {
      case ESinkType.FE2_TO_FE2: {
        iconCss += 'FE2Plugin';
        break;
      }
      case ESinkType.GEO_BYTE: {
        iconCss += 'GeoBytePlugin';
        break;
      }
      case ESinkType.BWB_OFFICE: {
        iconCss += 'BwbOffice';
        break;
      }case ESinkType.FIREBOARD:{
        iconCss += 'FireboardStatus';
      }
    }
    return iconCss;

  }

  reloadOnSinksChange() {
    if (!this.hasSinkPriv) {
      return;
    }
    this.restService.loadSinksForUser(this.currentPage, 10)
      .then((sinksResponse) => {
        this.sinks = sinksResponse;
      }).finally(() => {
      this.$scope.$applyAsync();
      // load previous page if last on page was deleted (if it wasn't first page)
      if (this.currentPage > 0 && (!this.sinks.content || this.sinks.content.length === 0)) {
        this.currentPage--;
        this.loadSinks();
      }
    });
  }

  loadSinks() {
    if (!this.hasSinkPriv) {
      return;
    }
    this.restService.loadSinksForUser(this.currentPage, 10)
      .then((sinksResponse) => {
        this.sinks = sinksResponse;
      }).finally(() => {
      this.$scope.$applyAsync()
    });
  }

  selectType(type: ESinkType) {
    this.typeSelected = type;
  }

  addSink() {
    if (!this.hasSinkPriv) {
      return;
    }
    this.restService.getEmptySinkOfType(this.typeSelected).then(newSink => {
      switch (this.typeSelected) {
        case ESinkType.FE2_TO_FE2: {
          this.openModalForType('edit.fe2sink.modal/edit.fe2sink.modal.html', 'EditFe2sinkModal', newSink);
          break;
        }
        case ESinkType.GEO_BYTE: {
          this.openModalForType('edit.geobyteSink.modal/edit.geobyteSink.modal.html', 'EditGeobyteModal', newSink);
          break;
        }
        case ESinkType.BWB_OFFICE: {
          this.openModalForType('edit.bwbOffice.modal/edit.bwbOffice.modal.html', 'EditBwbModal', newSink);
          break;
        }
        case ESinkType.FIREBOARD: {
          this.openModalForType('edit.fireboardSink.modal/edit.fireboardSink.modal.html', 'EditFireboardSinkModal', newSink);
        }
      }
    });

  }

  private openModalForType(htmlPath: string, controller: string, sink: SinkResponse) {
    this.$uibModal.open({
      template: require('../../../modals/sinks/' + htmlPath),
      controller: controller,
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        sink: () => {
          return sink;
        },
        isNew: () => {
          return true;
        }, okFunction: () => {
          return (refresh) => {
            // do not reload if cancel was clicked (no changes to display)
            if (refresh) {
              this.loadSinks();
            }
          }
        }
      }
    });
  }

  previousPage() {
    this.currentPage--;
    this.loadSinks();
  }

  nextPage() {
    this.currentPage++;
    this.loadSinks();
  }
}