import {IDocumentService} from 'angular';
import {AlarmDataSimple, AlarmVehicle, Protocol} from "./alarm.data"
import {VehicleNameIDResponse} from "./vehicles.data";
import {ESelectiveAlarmStatus} from './selective.alarms.data';

export interface EmergencySimple {
  externalId: string,
  timestamp: number,
  keyword: string,
  emoji: string,
  location_dest: string,
  state: string;
  timestampString: string,
  callerWithContact: string,
  keywordDescription: string,
  targetDest: string,
  pluginmessage: string,
  lat: string,
  lng: string;
  lat_routing: string;
  lng_routing: string;
  lat_foreign: string;
  lng_foreign: string;
  color: string;
  hasLocationMap: boolean;
  hasMissionReport: boolean;
  allAlarmsDeleted: boolean;
  closingAllowed: boolean;
  mapId: string;
  myMission: boolean;
  parentUser: string;
  parentUserId: string;
  shortenedMapId: string;
  withEmergencySignal: boolean;
}

export interface EmergencyResponse extends EmergencySimple {
  vehicles: EmergencyVehicleSimple[];
  reportState: EmergencyReportState;
}

export enum EmergencyReportState {
  NO_REPORTS = 'NO_REPORTS',
  ALL_PUBLISHED = 'ALL_PUBLISHED',
  WITH_DRAFTS = 'WITH_DRAFTS'
}


export interface EmergencyVehicleSimple {
  vehicleId: string;
  name: string;
  shortName: string;
}

export interface Emergency extends EmergencySimple {
  alarms: AlarmDataSimple[];
  vehicles: AlarmVehicle[];
  protocol: Protocol[];
  report: EmergencyReport;
  timeline: MissionTimeLineEntry[];
  images: EmergencyImageInfo[];
  selectiveAlarm: boolean;
  selectiveAlarmId: boolean;
  selectiveAlarmState: ESelectiveAlarmStatus;
}

export interface MissionReportSlice {
  content: MissionReportReduced[];
  last: boolean;
  first: boolean;
}

export interface MissionReportReduced {
  externalId: string;
  reportId: string;
  aMobileId: string;
  creationDate: number;
  lastEditorId: string;
  lastEditorName: string;
  updateDate: number;
  vehicle: VehicleNameIDResponse;
  revisionNumber: number;
  generalData: MissionReportGeneralRequestReduced;
  missionData: ReportedMissionDataRequestReduced;
}

export interface ReportedMissionDataRequestReduced {
  ownMissionLeader: MissionReportPersonRequest;
}

export interface MissionReportGeneralRequestReduced {
  endTime: number;

}

export interface MissionReport {
  externalId: string;
  reportId: string;
  aMobileId: string;
  revisionNumber: number;
  sourcePlatform: EReportSourcePlatform;
  updateDate: number;
  creationDate: number;
  vehicle: VehicleNameIDResponse;
  generalData: MissionReportGeneralRequest;
  missionData: ReportedMissionDataRequest;
  devices: MissionReportDevicesRequests;
  participants: MissionReportPaticipantsRequest[];
  reportResources: MissionReportResourcesRequest[];
  additionalResources: string;
  state: EMissionReportState;
}

export interface ReportedMissionDataRequest {
  missionLeader: MissionReportPersonRequest;
  ownMissionLeader: MissionReportPersonRequest;
  functionOwnMissionLeader: EOwnLeaderFunction;
  reportShort: string;
  personSaved: number;
  personFoundDead: number;
  animalsSaved: number;
  animalsFoundDead: number;
  fireScope: string;
  cause: string;
  specialOccurrences: string;
  emailsToNotify: string;
}

export interface MissionReportAddress {
  city: string;
  street: string;
  house: string;
}

export interface MissionReportGeneralRequest {
  startTime: number;
  endTime: number;
  keyword: string;
  keywordDescription: string;
  address: MissionReportAddress;
  building: MissionReportBuilding;
  chargeable: boolean;
  overLocal: boolean;
  stressful: boolean;
  drivenDistance: number;
}

export interface MissionReportPaticipantsRequest {
  person: MissionReportPersonRequest;
  agt: boolean;
  startTime: number;
  endTime: number;
  agtTime: number;
}

export interface MissionReportPersonRequest {
  personId: string;
  displayName: string;
  osId: number;
}

export interface MissionReportBuilding {
  id: string;
  name: string;
}

export interface MissionReportDevicesRequests {
  usedDevices: string;
  waterUsage: number;
  usedMaterials: string;
}

export interface MissionReportResourcesRequest {
  vehicleId: string;
  vehicleLeader: MissionReportPersonRequest;
}

export enum EOwnLeaderFunction {
  SBR = "SBR",
  SBI = "SBI",
  SBM = "SBM",
  KBR = "KBR",
  KBI = "KBI",
  KBM = "KBM",
  GROUP_LEADER = 'GROUP_LEADER',
  SQUADRON_LEADER = 'SQUADRON_LEADER',
  PLATOON_LEADER = 'PLATOON_LEADER',
  CHIEF = 'CHIEF',
  SECOND_CHIEF = "SECOND_CHIEF",
  ELD = 'ELD'
}

export enum EReportSourcePlatform {
  FE_2 = 'FE_2', A_MOBILE = 'A_MOBILe'
}

export enum EMissionReportState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export interface EmergencyReport {
  id: string;
  report_long: string;
  mission_leader: string;
  report_short: string;
  wounded: number;
  losses: number;
  animalRescues: number;
  cause: string;
  chargeable: boolean;
  over_local: boolean;
  stressful: boolean;
  lastModified: Date; // TODO: type (Instant?) -> we probably need a separate response type for EmergencyReport
  mission_end: Date; // TODO: type (Instant?)
  mission_start: Date; // TODO: type (Instant?)
  userId: string;
  externalId: string
}

export enum TimeLineType {
  START = 'START',
  END = 'END',
  MESSAGE = 'MESSAGE',
  CHANGE = 'CHANGE'
}

export interface MissionTimeLineEntry {
  type: TimeLineType;
  timestamp: number;
  message?: string;
}

export interface EmergencyImageInfo {
  creationTime: string;
  updateTime: string;
  type: string;
  filename: string;
  fileId: string;
  note: string;
  imgData: any;
  isOpen: boolean;
  lat: number;
  lng: number;
}

export interface LagekarteImageData {
  imgData: any;
}

export interface UpdateMissionStateRequest {
  state: EMissionReportState;
}