'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { VehicleAssignedAlarm } from "../../../../data/alarm.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import { EVehicleStatus, StatusEntryResponse } from "../../../../data/vehicles.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./alarm.assigned.row.entry.component.scss');

export default class AlarmAssignedRowEntryComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A';
    this.template = require('./alarm.assigned.row.entry.component.html');
    this.scope = {
      alarm: '=',
      timer: '=',
      hasRoutingFeature: '='
    };
    this.controller = AlarmAssignedRowEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  };
};
class AlarmAssignedRowEntryComponentController {
  public alarm: VehicleAssignedAlarm;
  private $rootScope: IRootScopeService;
  private $log: ILogService;
  private $uibModal: any;
  private $scope: IScope;
  private listeners = [];
  private restService: RestService;
  private isLoading: boolean = false;

  private timer: number;

  public displayArrivalTime = false;
  public estimatedTimeOfArrivalCls = 'alarm-assignment-vehicle-estimation-green';
  public calculatedTimeOfArrivalCls = '';

  public hasRoutingFeature = false;

  public estimatedTimeOfArrival: number;
  public calculatedTimeOfArrival: number;
  public estimatedTimeOfArrivalInMinutes = '-';
  public calculatedTimeOfArrivalInMinutes = '-';

  constructor($rootScope: IRootScopeService, $log: ILogService, $scope: IScope, private privilegeService: PrivilegeService, $uibModal: any,restService: RestService) {
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$log = $log;
    this.restService = restService;

    this.$scope.$watch('ctrl.timer', () => {
      // This value will be updated every ten seconds to update the estimated time of arrival
      if (!this.displayArrivalTime || !this.alarm) {
        return;
      }
      this.calculatePrediction();
    });

    this.$scope.$watch('ctrl.alarm', (oldValue, newVehicle: VehicleAssignedAlarm) => {
      if (newVehicle && this.listeners.length === 0) {
        // Register for topic
        const changeEvent = 'status.change.' + this.alarm.vehicleId;
        this.displayArrivalTime = this.alarm.status === EVehicleStatus.STATUS_3;
        if (this.alarm.calculatedTimeOfArrival) {
          this.calculatedTimeOfArrival = (new Date(this.alarm.calculatedTimeOfArrival)).getTime();
        }
        if (this.alarm.estimatedTimeOfArrival) {
          this.estimatedTimeOfArrival = (new Date(this.alarm.estimatedTimeOfArrival)).getTime();
        }
        this.calculatePrediction();
        this.$log.debug(`Register for change events of ${newVehicle.vehicleName} on ${changeEvent}`);
        this.listeners.push(this.$rootScope.$on(changeEvent, (event, data: StatusEntryResponse) => {
          this.alarm.status = data.status;
          this.alarm.statusChangedTimestamp = new Date(data.timestamp);
          this.alarm.statusColor = data.color;
          this.alarm.statusTextColor = data.textColor;
          this.displayArrivalTime = this.alarm.status === EVehicleStatus.STATUS_3;
          this.reloadOnStatusChange();
          this.$scope.$applyAsync();
        }));

        this.listeners.push(this.$rootScope.$on(`updated.assignedAlarm.${this.alarm.vehicleId}`, (event, data: VehicleAssignedAlarm) => {
          this.alarm = data;
          if (this.alarm.calculatedTimeOfArrival) {
            this.calculatedTimeOfArrival = (new Date(this.alarm.calculatedTimeOfArrival)).getTime();
          }
          this.calculatePrediction();
        }));
      }
    });


    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  calculatePrediction(): void {
    const now = this.timer ? this.timer : (new Date()).getTime();
    if (this.alarm.estimatedTimeOfArrival) {
      const estimatedTimeOfArrival = Math.ceil((this.estimatedTimeOfArrival - now) / 1000 / 60);
      if (estimatedTimeOfArrival > 0) {
        this.estimatedTimeOfArrivalInMinutes = '+' + estimatedTimeOfArrival;

        if (estimatedTimeOfArrival >= 10) {
          this.estimatedTimeOfArrivalCls = 'alarm-assignment-vehicle-estimation-red';
        } else if (estimatedTimeOfArrival >= 5) {
          this.estimatedTimeOfArrivalCls = 'alarm-assignment-vehicle-estimation-orange';
        } else {
          this.estimatedTimeOfArrivalCls = 'alarm-assignment-vehicle-estimation-green';
        }
      } else {
        this.estimatedTimeOfArrivalInMinutes = '' + estimatedTimeOfArrival;
        this.estimatedTimeOfArrivalCls = 'alarm-assignment-vehicle-estimation-black';
      }
    }
    if (this.alarm.calculatedTimeOfArrival) {
      const calculatedTimeOfArrival = Math.ceil((this.calculatedTimeOfArrival - now) / 1000 / 60);
      if (calculatedTimeOfArrival > 0) {
        this.calculatedTimeOfArrivalInMinutes = '+' + calculatedTimeOfArrival;
        if (calculatedTimeOfArrival >= 10) {
          this.calculatedTimeOfArrivalCls = 'alarm-assignment-vehicle-estimation-red';
        } else if (calculatedTimeOfArrival >= 5) {
          this.calculatedTimeOfArrivalCls = 'alarm-assignment-vehicle-estimation-orange';
        } else {
          this.calculatedTimeOfArrivalCls = 'alarm-assignment-vehicle-estimation-green';
        }
      } else {
        this.calculatedTimeOfArrivalInMinutes = '' + calculatedTimeOfArrival;
        this.calculatedTimeOfArrivalCls = 'alarm-assignment-vehicle-estimation-black';
      }
    }
  }

  openMission() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/alarms/mission.modal/mission.modal.html'),
      controller: 'MissionModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'hu',
      resolve: {
        externalId: () => this.alarm.externalId,
        parentUserId: () => undefined,
        selectiveAlarmId: () => undefined,
        allAlarmsDeletedFunction: () => {
          return () => {
            // nothing to do here
          }
        }
      }
    })
  }

  openMap() {
    if (this.isLoading){
      // don't open map while reloading current location with current status
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.alarm.lat,
            lng: this.alarm.lng,
            accuracy: 0
          }
        },
        position: () => {
          return this.alarm.currentPosition;
        },
        okFunction: () => {
          return (coords) => {
            // Nothing to do
          }
        }
      }
    });

  }

  private reloadOnStatusChange() {
    // only reload if a position was available
    if (this.alarm.hasPosition){
      this.isLoading = true;
      // simply reload so every data field is automatically set correctly
      this.restService.loadVehicleLocation(this.alarm.vehicleId).then((response) => {
        this.alarm.currentPosition = response;
      }).finally(() => {
        this.isLoading = false;
      });
    }

  }
}
