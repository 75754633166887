'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { UserAccount } from "../../../../data/account.data";
import RestService from "../../../../services/rest.service";

require('./admin.relais.component.scss');

export default class AdminRelaisComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./admin.relais.component.html');
    this.scope = {
    };
    this.controller = AdminRelaisComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}


class AdminRelaisComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public restService: RestService;
  public $log: ILogService;
  public hasRelais = false;
  public isLoading = false;
  public relais: any;
  private account: UserAccount;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, restService: RestService, public dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.restService = restService;
    this.$log = $log;
    this.hasRelais = false;
    if (this.dataService.hasAccount()) {
      this.account = this.dataService.getAccount();
      this.load();
    } else {
      let subscription = this.$rootScope.$on('new.account', (event, account) => {
        this.account = account;
        this.load();
        subscription();
      });
    }
  }


  /**
   * Load all users
   */
  load() {
    this.isLoading = true;

    if (this.account.admin) {
      this.restService.getAllRelaisForAdmin().then(relais => {
        this.relais = relais;
        this.hasRelais = relais.nbrOfRelais > 0;
      }).catch((err) => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
    } else {
      this.restService.getAllRelaisForUser().then(relais => {
        this.relais = relais;
        this.hasRelais = relais.nbrOfRelais > 0;
      }).catch((err) => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
    }


  };
}

