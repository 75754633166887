'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import angular = require("angular");
import {EFE2Features, UserAccount} from "../../../../data/account.data";
import {
  AlarmFakerRequest,
  AlarmRequestSimple, AlarmScheduleRequestSimple,
  AlarmSendData,
  Keyword,
  ManualAlarmBuilding,
  ManualAlarmDetails, ScheduleRequest
} from "../../../../data/alarm.data";
import {Person, PersonQuickEdit, PersonQuickEditResponse} from "../../../../data/person.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {AlarmTemplate} from "../../../../data/template.data";
import {Unit, UnitFilterEntry, UnitFilterResponse} from "../../../../data/unit.data";
import AccountService from "../../../../services/account.service";
import {Vehicle, VehicleSimple, VehiclesSimplePaginatedResponse} from "../../../../data/vehicles.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import {SortParams} from "../../../views/addressbook.view.component/addressbook.view.component";
import {AlarmGroupSimple} from "../../../../data/alarmgroup.data";
import {AgeVerificationSetting} from "../../../../data/admin.settings";

require('./alarm.component.css');

//sendAlarmView
export default class AlarmComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.component.html');
    this.scope = {
      account: '='
    };
    this.controller = AlarmComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmComponentController {
  public $scope: IScope;
  public $translate: any;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public helperService: any;
  public dataService: any;
  public Notification: any;
  public $stateParams: any;
  public $uibModal: any;
  public status = {
    showMisc: false
  } as UIStatus;
  public quickEdit: PersonQuickEdit[] = [];
  public misc = {
    subric: '',
    withStatistic: true,
    generateExternalId: true,
    addVehiclesForFakeAlarm: true
  } as SelectedOptions;

  public atLeastOneUnitSelected = false;
  public quickEditActive = false;

  public isLoadingPersons = false;
  public params = {
    sortType: 'displayName',
    sortReverse: false,
    searchFilter: '',
    currentPage: 0,
    totalElements: 0,
    pageSize: 20
  } as SortParams;
  public addressbook: PersonQuickEditResponse;

  // Vehicles
  public vehicles: VehiclesSimplePaginatedResponse;
  public selectedVehicles: Vehicle[] = [];
  public isLoadingVehicles = false;
  public vehicleParams = {
    searchFilter: '',
    currentPage: 0,
    totalElements: 0,
    pageSize: 20
  } as SortParams;
  public selectedUserIds: string[] = [];


  public isSecureButtonSelected = false;
  public personInQuickAlarmWhichIsNotAvailable = false;
  public isLoading = false;
  public isSearchLoading = false;
  public onlineservice: any;
  public account: UserAccount;

  public units: Unit[] = [];
  public unitsForAlarm: Unit[] = [];
  public unitsNotForAlarm: Unit[] = [];

  public address: any;

  public listeners = [];

  public allUsers: UnitFilterResponse;
  public selectedUser: UnitFilterEntry;
  public searchFilter = '';

  public selectedTemplate: AlarmTemplate;

  public fakerFeatureEnabled = false;
  public alarmScheduleFeatureEnabled = false;
  public manualAlarmEdit: ManualAlarmDetails = {} as ManualAlarmDetails;

  public alarmGroups: AlarmGroupSimple[] = [];
  public selectedAlarmgroupIds: string[] = [];
  public isLoadingAlarmgroups = false;
  public ageVerificationSetting: AgeVerificationSetting;
  public schedule: ScheduleRequest;
  public schedules: any;

  constructor($scope: IScope, $rootScope: IRootScopeService, $translate, restService: RestService, helperService, dataService, $log: ILogService, Notification, $uibModal, $stateParams,
              public privilegeService: PrivilegeService, private accountService: AccountService) {
    this.$scope = $scope;
    this.$translate = $translate;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;
    this.helperService = helperService;
    this.dataService = dataService;
    this.Notification = Notification;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

    this.init();
    this.loadAlarmGroups();
  }

  /**
   * Init data model
   */
  init() {
    this.$log.debug('Init alarm.component...');
    this.schedules = ['NOW', 'ONCE', 'REPEATING'];
    this.fakerFeatureEnabled = this.accountService.isFeatureEnabled(EFE2Features.FAKER);
    this.alarmScheduleFeatureEnabled = this.accountService.isFeatureEnabled(EFE2Features.SCHEDULE_ALARM);
    this.loadAgeVerificationSetting();
    this.searchForUnit().then(() => {
      for (var unit of this.selectedUser.units) {
        if (this.$stateParams.code) {
          if (unit.code === this.$stateParams.code) {
            this.addOrRemoveUnitToAlarm(unit);
            break;
          }
        }
      }
    });

    var now = new Date();
    now.setTime(now.getTime() + (1*60*60*1000)); // + 1 hour
    now.setSeconds(0, 0);
    this.schedule = {
      infoMessageScheduleType: 'NOW',
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        timeOnce: now,
        timeRepeating: now.toLocaleTimeString('de-De', {
        hour: "2-digit",
        minute: "2-digit",
        second: undefined
      })
    } as ScheduleRequest;
  }

  isScheduleValid(){
    if(this.schedule.infoMessageScheduleType === 'ONCE'){
      return this.schedule.timeOnce > new Date();
    }
    if(this.schedule.infoMessageScheduleType === 'REPEATING'){
      return this.schedule.monday || this.schedule.tuesday ||this.schedule.wednesday ||this.schedule.thursday ||this.schedule.friday ||this.schedule.saturday ||this.schedule.sunday;
    }
    return true;
  }

  loadAgeVerificationSetting() {
    this.restService.getAgeVerificationSetting().then((ageVerification) => {
      this.ageVerificationSetting = ageVerification;
    }).catch(error => {
      this.$log.error(error);
    })
  }


  getAgeClass(age: number) {
    if (this.ageVerificationSetting != null && this.ageVerificationSetting.useAgeVerification) {
      var styleByAge = this.helperService.getStyleByAge(age, this.ageVerificationSetting.minAge, this.ageVerificationSetting.maxAge);
      return "label label-" + styleByAge;
    }
    return " label";
  }

  loadAlarmGroups() {
    this.isLoadingAlarmgroups = true;
    this.restService.loadAlarmGroupsSimple().then(groups => {
      this.alarmGroups = groups;
    }).finally(() => {
      this.isLoadingAlarmgroups = false;
    })
  }

  toggleAlarmgroupSelection(group: AlarmGroupSimple) {
    if (this.isSelected(group)) {
      this.selectedAlarmgroupIds = this.selectedAlarmgroupIds.filter(groupId => groupId !== group.id);
    } else {
      this.selectedAlarmgroupIds.push(group.id);
    }
    this.pageChanged(true);
  }

  isSelected(group: AlarmGroupSimple) {
    return this.selectedAlarmgroupIds.some(groupId => groupId === group.id);
  }

  loadVehicles() {
    this.isLoadingVehicles = true;
    this.restService.loadVehiclesPaginatedSimple(this.vehicleParams.currentPage === 0 ? 0 : this.vehicleParams.currentPage - 1,
      this.vehicleParams.pageSize,
      this.vehicleParams.searchFilter,
      'ASC', "",
      this.selectedUserIds).then(vehicles => {
      this.vehicles = vehicles;
    }).finally(() => {
      this.isLoadingVehicles = false;
      this.$scope.$applyAsync();
    })
  }

  /**
   * Search and load all units for all users (if available)
   */
  searchForUnit() {
    return new Promise<void>((resolve, reject) => {
      this.isSearchLoading = true;
      this.restService.searchForUnits(this.searchFilter).then(result => {
        this.allUsers = result;
        if (!this.selectedUser) {

          if (this.account) {
            var currentUser = this.allUsers.users.filter(usr => usr.username === this.account.username);
            if (currentUser.length > 0) {
              // Select current user as default
              this.selectUser(currentUser[0]);
            } else {
              this.selectUser(this.allUsers.users[0]);
            }
          } else {
            this.selectUser(this.allUsers.users[0]);
          }

        }
      }).finally(() => {
        this.isSearchLoading = false;
        this.$scope.$applyAsync();
        resolve();
      });
    });
  }

  /**
   * Reset search
   */
  resetSearchAndReload() {
    this.searchFilter = '';
    this.searchForUnit();
  }

  /**
   * Rest vehicle search
   */
  resetVehicleSearchAndReload() {
    this.vehicleParams.searchFilter = '';
    this.loadVehicles();
  }

  personIsInQuickEdit(person: PersonQuickEdit) {
    return this.quickEdit.filter(entry => entry.personID === person.personID).length > 0;
  }

  clearQuickEdit() {
    this.selectedAlarmgroupIds = [];
    this.quickEdit = [];
    this.pageChanged();
  }


  /**
   * Add person to list of quick edits
   * @param person
   */
  addToQuickEdit(person: PersonQuickEdit, removeIfExistent: boolean = true) {

    const alreadyAdded = this.personIsInQuickEdit(person);

    if (alreadyAdded) {
      if (removeIfExistent) {
        const addedPerson = this.quickEdit.filter(entry => entry.personID === person.personID)[0];
        this.quickEdit.splice(this.quickEdit.indexOf(addedPerson), 1);
        if (this.quickEdit.length === 0) {
          this.selectedAlarmgroupIds = [];
        }
      }
    } else {
      this.quickEdit.push(person);
    }

    // Check if any of selected persons is not available
    this.personInQuickAlarmWhichIsNotAvailable = false;
    if (angular.isDefined(this.onlineservice)) {

      this.quickEdit.forEach(person => {
        var availability = person.availability;
        if ('NOT_AVAILABLE' === availability) {
          this.personInQuickAlarmWhichIsNotAvailable = true;
        }
      });
    }
  };

  resetSearchAndReloadPersonsQuickEdit() {
    this.params.searchFilter = '';
    this.searchPersonsQuickEdit();

  }

  searchPersonsQuickEdit() {
    this.params.currentPage = 0;
    this.pageChanged();
  }

  /**
   * Checks if at least one unit has quick edit active
   */
  updateQuickEditState() {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Quickedit)) {
      return;
    }
    this.atLeastOneUnitSelected = this.unitsForAlarm.length > 0;
    this.quickEditActive = false;
    for (var i = 0; i < this.unitsForAlarm.length; i++) {
      if (this.unitsForAlarm[i].hasQuickEdit) {
        this.quickEditActive = true;
        break;
      }
    }

    if (!this.quickEditActive && this.quickEdit.length > 0) {
      // Reset quick edit
      this.quickEdit = [];
      this.personInQuickAlarmWhichIsNotAvailable = false;
    }

    if (this.quickEditActive && !this.addressbook) {
      // Load persons
      this.pageChanged();
    }
  };

  /**
   Add or remove a unit to the current alarm. Only if user is admin
   */
  addOrRemoveUnitToAlarm(unit: Unit) {
    const filtered = this.unitsForAlarm.filter(u => u.code === unit.code);
    if (filtered.length !== 0 || unit.settings.disabled) {
      this.unitsForAlarm.splice(this.unitsForAlarm.indexOf(filtered[0]), 1);
      this.unitsNotForAlarm.push(unit);
    } else {
      this.unitsForAlarm.push(unit);
      const filtered = this.unitsNotForAlarm.filter(u => u.code === unit.code);
      this.unitsNotForAlarm.splice(this.unitsNotForAlarm.indexOf(filtered[0]), 1);
    }

    this.atLeastOneUnitSelected = this.unitsForAlarm.length > 0;
    this.updateQuickEditState();

    const oldUserIds = this.selectedUserIds;
    this.selectedUserIds = [];
    this.unitsForAlarm.forEach(unit => {
      const userId = unit.userId;
      if (!this.selectedUserIds.includes(userId)) {
        this.selectedUserIds.push(userId);
      }
    });

    const intersection = oldUserIds.filter(element => this.selectedUserIds.includes(element));

    if (oldUserIds.length != this.selectedUserIds.length || intersection.length !== 0) {
      // Trigger reload
      this.loadVehicles();
    }
  };

  /**
   * Select/Deselect a vehicle
   * @param vehicle
   */
  selectVehicle(vehicle: Vehicle) {
    const filtered = this.selectedVehicles.filter(v => v.id === vehicle.id);
    if (filtered.length > 0) {
      this.selectedVehicles.splice(this.selectedVehicles.indexOf(filtered[0]), 1);
    } else {
      this.selectedVehicles.push(vehicle);
    }
  }

  /*
    If unit is selected for alarm
  */
  isUnitSelected(unit: Unit) {
    return this.unitsForAlarm.includes(unit);
  };


  /*
    If person is selected for alarm
  */
  isPersonSelected(person: Person) {
    return this.quickEdit.includes(person);
  };

  /**
   * Returns true if vehicle is selected
   */
  isVehicleSelected(vehicle: Vehicle) {
    return this.selectedVehicles.filter(v => v.id === vehicle.id).length > 0;
  }

  /**
   * Load or search for persons
   * @param search
   * @returns
   */
  pageChanged(afterGroupChange?: boolean) {

    return new Promise<void>((resolve, reject) => {
      this.isLoadingPersons = true;
      const ids = this.selectedAlarmgroupIds.join(',');
      this.restService.loadPersonsForQuickEdit(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.searchFilter, this.params.sortReverse ? 'DESC' : 'ASC', this.params.pageSize, 'aPagerPro', true, ids)
        .then((result: PersonQuickEditResponse) => {
          this.addressbook = result;
          if (afterGroupChange) {
            //we have alarmgroup ids, we preselect the persons
            this.quickEdit = [];
            if (ids.length > 0) {
              this.addressbook.persons.forEach(person => {
                this.addToQuickEdit(person, false);
              });
            }
          }
          this.params.totalElements = this.addressbook.totalElements;
          this.params.totalPages = this.addressbook.totalPages;
        }).finally(() => {
        this.isLoadingPersons = false;
        this.$scope.$applyAsync();
        resolve();
      });
    });
  }

  //#####
  //Alarm
  //#####

  sendAlarm() {
    this.isLoading = true;
    this.isSecureButtonSelected = false;

    //Gather all data
    var alarm: AlarmRequestSimple = {
      data: {} as AlarmSendData,
      units: '',
      vehicles: [],
      externalId: ''
    };


    alarm.data.message = this.manualAlarmEdit.alarmText;
    //Keyword
    if (this.manualAlarmEdit.selectedKeyword) {
      alarm.data.keyword = this.manualAlarmEdit.selectedKeyword;
    }
    //Keyword

    if (this.manualAlarmEdit.keyword) {
      if (this.manualAlarmEdit.keyword.additional) alarm.data.keyword_misc = this.manualAlarmEdit.keyword.additional;
      if (this.manualAlarmEdit.keyword.category) alarm.data.keyword_category = this.manualAlarmEdit.keyword.category;
      if (this.manualAlarmEdit.keyword.ident) alarm.data.keyword_ident = this.manualAlarmEdit.keyword.ident;
      if (this.manualAlarmEdit.keyword.color) alarm.data.keyword_color = this.manualAlarmEdit.keyword.color;
      if (this.manualAlarmEdit.keyword.ser) alarm.data.keyword_ser = this.manualAlarmEdit.keyword.ser;

    }
    //Description
    if (this.manualAlarmEdit.selectedKeyword) {
      alarm.data.keyword_description = this.manualAlarmEdit.selectedKeywordDescription;
    }
    //Street
    if (this.manualAlarmEdit.selectedStreet) {
      alarm.data.street = this.manualAlarmEdit.selectedStreet;
    }
    //House
    if (this.manualAlarmEdit.selectedHouse) {
      alarm.data.house = this.manualAlarmEdit.selectedHouse;
    }
    //City
    if (this.manualAlarmEdit.selectedCity) {
      alarm.data.city = this.manualAlarmEdit.selectedCity;
    }
    //PostalCode
    if (this.manualAlarmEdit.selectedPostalCode) {
      alarm.data.postalCode = this.manualAlarmEdit.selectedPostalCode;
    }
    //Abbreviation
    if (this.manualAlarmEdit.selectedAbbreviation) {
      alarm.data.city_abbr = this.manualAlarmEdit.selectedAbbreviation;
    }

    //Building
    if (this.manualAlarmEdit.selectedBuilding) {
      alarm.data.building = this.manualAlarmEdit.selectedBuilding;
    }

    if (this.manualAlarmEdit.lat && this.manualAlarmEdit.lat > 0 && this.manualAlarmEdit.lng && this.manualAlarmEdit.lng > 0) {
      alarm.data.lat = this.manualAlarmEdit.lat;
      alarm.data.lng = this.manualAlarmEdit.lng;
    }

    if (this.manualAlarmEdit.building) {
      alarm.data.building = this.manualAlarmEdit.building.building;

      if (this.manualAlarmEdit.building.additional) {
        alarm.data.location_additional = this.manualAlarmEdit.building.additional;
      }

      if (this.manualAlarmEdit.building.abbreviation) {
        alarm.data.city_abbr = this.manualAlarmEdit.building.abbreviation;
      }

      if (this.manualAlarmEdit.building.id) {
        alarm.data.building_id = this.manualAlarmEdit.building.id;
      }

      if (this.manualAlarmEdit.building.ident) {
        alarm.data.building_ident = this.manualAlarmEdit.building.ident;
      }

      if (this.manualAlarmEdit.building.alarmObjectType) {
        alarm.data.building_type = this.manualAlarmEdit.building.alarmObjectType;
      }

      if (this.manualAlarmEdit.building.lat && this.manualAlarmEdit.building.lat > 0 && this.manualAlarmEdit.building.lng && this.manualAlarmEdit.building.lng > 0) {
        alarm.data.lat = this.manualAlarmEdit.building.lat;
        alarm.data.lng = this.manualAlarmEdit.building.lng;
      }

    }

    alarm.data.withStatistic = this.misc.withStatistic;
    alarm.data.generateExternalId = this.misc.generateExternalId;

    if (this.misc.subric !== '') {
      alarm.data.subric = this.misc.subric;
    }
    alarm.data.alarmType = 'MANUAL';


    if (this.quickEdit.length > 0) {
      //Quickedit
      alarm.data.quickEditActive = 'true';
      var ids = '';
      for (var i = 0; i < this.quickEdit.length; i++) {
        ids = ids + this.quickEdit[i].personID;
        if (i < this.quickEdit.length) {
          ids = ids + ';';
        }
      }
      alarm.data.quickEditIDs = ids;
    }


    //Units
    var units = '';
    for (var ii = 0; ii < this.unitsForAlarm.length; ii++) {
      units = units + this.unitsForAlarm[ii].code;
      if (ii < this.unitsForAlarm.length - 1) {
        units = units + ';';
      }
    }
    alarm.units = units;

    this.selectedVehicles.forEach(vehicle => alarm.vehicles.push(
      {
        id: vehicle.id,
        name: vehicle.name,
        shortName: vehicle.shortName,
        status: vehicle.status,
        statusColor: vehicle.statusColor
      } as VehicleSimple)
    );

    this.restService.sendAlarm(alarm,
      () => {
        this.isLoading = false;
        //Show popup
        this.$translate(['ALARM.SUCCESS_MESSAGE', 'ALARM.SUCCESS_TITLE']).then((translations) => {
          this.Notification.success({
            message: translations['ALARM.SUCCESS_MESSAGE'],
            title: translations['ALARM.SUCCESS_TITLE']
          });
        });
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });

  };
  sendScheduleAlarm() {
    this.isLoading = true;
    this.isSecureButtonSelected = false;

    //Gather all data
    var alarm: AlarmScheduleRequestSimple = {
      data: {} as AlarmSendData,
      units: '',
      vehicles: [],
      externalId: '',
      schedule: this.schedule as ScheduleRequest
    } as AlarmScheduleRequestSimple;

    alarm.data.message = this.manualAlarmEdit.alarmText;
    //Keyword
    if (this.manualAlarmEdit.selectedKeyword) {
      alarm.data.keyword = this.manualAlarmEdit.selectedKeyword;
    }
    //Keyword

    if (this.manualAlarmEdit.keyword) {
      if (this.manualAlarmEdit.keyword.additional) alarm.data.keyword_misc = this.manualAlarmEdit.keyword.additional;
      if (this.manualAlarmEdit.keyword.category) alarm.data.keyword_category = this.manualAlarmEdit.keyword.category;
      if (this.manualAlarmEdit.keyword.ident) alarm.data.keyword_ident = this.manualAlarmEdit.keyword.ident;
      if (this.manualAlarmEdit.keyword.color) alarm.data.keyword_color = this.manualAlarmEdit.keyword.color;
      if (this.manualAlarmEdit.keyword.ser) alarm.data.keyword_ser = this.manualAlarmEdit.keyword.ser;

    }
    //Description
    if (this.manualAlarmEdit.selectedKeyword) {
      alarm.data.keyword_description = this.manualAlarmEdit.selectedKeywordDescription;
    }
    //Street
    if (this.manualAlarmEdit.selectedStreet) {
      alarm.data.street = this.manualAlarmEdit.selectedStreet;
    }
    //House
    if (this.manualAlarmEdit.selectedHouse) {
      alarm.data.house = this.manualAlarmEdit.selectedHouse;
    }
    //City
    if (this.manualAlarmEdit.selectedCity) {
      alarm.data.city = this.manualAlarmEdit.selectedCity;
    }
    //PostalCode
    if (this.manualAlarmEdit.selectedPostalCode) {
      alarm.data.postalCode = this.manualAlarmEdit.selectedPostalCode;
    }
    //Abbreviation
    if (this.manualAlarmEdit.selectedAbbreviation) {
      alarm.data.city_abbr = this.manualAlarmEdit.selectedAbbreviation;
    }

    //Building
    if (this.manualAlarmEdit.selectedBuilding) {
      alarm.data.building = this.manualAlarmEdit.selectedBuilding;
    }

    if (this.manualAlarmEdit.lat && this.manualAlarmEdit.lat > 0 && this.manualAlarmEdit.lng && this.manualAlarmEdit.lng > 0) {
      alarm.data.lat = this.manualAlarmEdit.lat;
      alarm.data.lng = this.manualAlarmEdit.lng;
    }

    if (this.manualAlarmEdit.building) {
      alarm.data.building = this.manualAlarmEdit.building.building;

      if (this.manualAlarmEdit.building.additional) {
        alarm.data.location_additional = this.manualAlarmEdit.building.additional;
      }

      if (this.manualAlarmEdit.building.abbreviation) {
        alarm.data.city_abbr = this.manualAlarmEdit.building.abbreviation;
      }

      if (this.manualAlarmEdit.building.id) {
        alarm.data.building_id = this.manualAlarmEdit.building.id;
      }

      if (this.manualAlarmEdit.building.ident) {
        alarm.data.building_ident = this.manualAlarmEdit.building.ident;
      }

      if (this.manualAlarmEdit.building.alarmObjectType) {
        alarm.data.building_type = this.manualAlarmEdit.building.alarmObjectType;
      }

      if (this.manualAlarmEdit.building.lat && this.manualAlarmEdit.building.lat > 0 && this.manualAlarmEdit.building.lng && this.manualAlarmEdit.building.lng > 0) {
        alarm.data.lat = this.manualAlarmEdit.building.lat;
        alarm.data.lng = this.manualAlarmEdit.building.lng;
      }

    }

    alarm.data.withStatistic = this.misc.withStatistic;
    alarm.data.generateExternalId = this.misc.generateExternalId;

    if (this.misc.subric !== '') {
      alarm.data.subric = this.misc.subric;
    }
    alarm.data.alarmType = 'MANUAL';


    if (this.quickEdit.length > 0) {
      //Quickedit
      alarm.data.quickEditActive = 'true';
      var ids = '';
      for (var i = 0; i < this.quickEdit.length; i++) {
        ids = ids + this.quickEdit[i].personID;
        if (i < this.quickEdit.length) {
          ids = ids + ';';
        }
      }
      alarm.data.quickEditIDs = ids;
    }


    //Units
    var units = '';
    for (var ii = 0; ii < this.unitsForAlarm.length; ii++) {
      units = units + this.unitsForAlarm[ii].code;
      if (ii < this.unitsForAlarm.length - 1) {
        units = units + ';';
      }
    }
    alarm.units = units;

    this.selectedVehicles.forEach(vehicle => alarm.vehicles.push(
      {
        id: vehicle.id,
        name: vehicle.name,
        shortName: vehicle.shortName,
        status: vehicle.status,
        statusColor: vehicle.statusColor
      } as VehicleSimple)
    );

    this.restService.planeAlarm(alarm,
      () => {
        this.isLoading = false;
        //Show popup
        this.$translate(['ALARM_SCHEDULE.SUCCESS_MESSAGE', 'ALARM_SCHEDULE.SUCCESS_TITLE']).then((translations) => {
          this.Notification.success({
            message: translations['ALARM_SCHEDULE.SUCCESS_MESSAGE'],
            title: translations['ALARM_SCHEDULE.SUCCESS_TITLE']
          });
        });
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });

  };
  /**
   * Create a fake alarm with fake data
   */
  sendFakeAlarm(isUpdate: boolean) {
    this.isLoading = true;
    let request = {
      unitIds: this.unitsForAlarm.map(unit => unit.id),
      update: isUpdate,
      vehicles: this.selectedVehicles,
      addVehiclesForFakeAlarm: this.misc.addVehiclesForFakeAlarm
    } as AlarmFakerRequest;
    this.restService.sendFakeAlarm(request).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  //#####
  //Typeahead
  //#####


  selectUser(user: UnitFilterEntry) {
    this.selectedUser = user;
  }


  /**
   * Select a template
   */
  selectTemplate(template: AlarmTemplate) {
    // Reset
    for (let i = 0; i < this.unitsForAlarm.length; i++) {
      this.unitsNotForAlarm.push(this.unitsForAlarm[i]);
    }
    this.unitsForAlarm = [];

    template.units.forEach(unit => {
      this.allUsers.users.forEach(user => {
        const unitInList = user.units.filter(u => u.id === unit.id);
        if (unitInList.length > 0) {
          this.addOrRemoveUnitToAlarm(unitInList[0]);
        }
      });

    });

    this.selectedTemplate = template;
    if (this.selectedTemplate.generateExternalId) {
      this.misc.generateExternalId = true;
    }
  };

  getTemplates(name: string) {
    return this.restService.searchForAlarmTemplates(name)
  }

  resetTemplate() {
    this.selectedTemplate = null;
    // Reset
    for (let i = 0; i < this.unitsForAlarm.length; i++) {
      this.unitsNotForAlarm.push(this.unitsForAlarm[i]);
    }
    this.unitsForAlarm = [];
    this.manualAlarmEdit.alarmText = null;
    this.manualAlarmEdit.selectedKeyword = null;
    this.manualAlarmEdit.keyword = {} as Keyword;
    this.manualAlarmEdit.keyword.additional = null;
    this.manualAlarmEdit.selectedKeywordDescription = null;
    this.manualAlarmEdit.selectedStreet = null;
    this.manualAlarmEdit.selectedHouse = null;
    this.manualAlarmEdit.selectedCity = null;
    this.manualAlarmEdit.selectedPostalCode = null;
    this.manualAlarmEdit.selectedAbbreviation = null;
    this.manualAlarmEdit.building = {} as ManualAlarmBuilding;
    this.manualAlarmEdit.selectedBuilding = null;
    this.manualAlarmEdit.lat = undefined;
    this.manualAlarmEdit.lng = undefined;

  }

}


interface UIStatus {
  showMisc: boolean
}

interface SelectedOptions {
  withStatistic: boolean,
  subric: string,
  generateExternalId: boolean,
  addVehiclesForFakeAlarm: boolean
}