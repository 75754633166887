'use strict';

import {ILogService, IRootScopeService, IScope, IWindowService} from "angular";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {SectionsResponse, SectionsTemplate, SectionsTemplatePaginated} from "../../../../data/sections.templates.data";

require('./sections.templates.component.scss');

export default class SectionsTemplatesComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {
        addressbook: '='
      }
    this.template = require('./sections.templates.component.html')

    this.controller = SectionsTemplatesController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class SectionsTemplatesController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $window: IWindowService;
  public $http: any;
  public $uibModal: any;
  public Notification: any;
  public $translate: any;
  public restService: RestService;
  public listeners = [];
  public isLoading = true;
  public data: SectionsTemplatePaginated;
  public selectedTemplate: SectionsTemplate;
  public current: SectionsResponse;
  public priv: PrivilegeService;
  public hasTemplates: boolean = false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, $window: IWindowService, $http, $uibModal, $translate, Notification, restService: RestService, privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$window = $window;
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.$translate = $translate;
    this.Notification = Notification;
    this.restService = restService;
    this.priv = privilegeService;
    this.initListeners();
  }

  /**
   * Loads the Templates paginated
   * @returns
   */
  pageChanged() {
    if (!this.hasTemplates) {
      return Promise.reject("Missing Permission");
    }

    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadSectionsTemplate()
        .then((result: SectionsTemplatePaginated) => {
          this.data = result;
          if (this.data.content && this.data.content.length > 0) {
            this.selectTemplate(this.data.content[0]);
          }
        }).catch(err => this.$log.error(err))
        .finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('update.sectionsTemplate.list', () => {
      this.pageChanged();
    }));

    this.listeners.push(this.$rootScope.$on('templates.view.tab', (event, source) => {
      if (source === 1) {
        this.hasTemplates = this.priv.has(RolePrivilege.Templates_Locationmap_Section);
        // Trigger reload
        this.pageChanged();
      }
    }));
    this.listeners.push(this.$rootScope.$on('sections.templates.state', (event, source) => {
      let index = this.data.content.findIndex(x => x.id === source.id);
      if (index >= 0) {
        this.data[index] = source;
        this.$scope.$applyAsync();
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  addTemplate() {

    this.$uibModal.open({
      template: require('../../../modals/objects/add.object.modal/add.object.modal.html'),
      controller: 'AddAlarmObjectCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name) => {
            this.isLoading = true;
            this.restService.addTemplate(name).then((sectionsTemplates) => {
              this.isLoading = false;
              // Open new created
              this.data.content.push(sectionsTemplates);
              this.selectTemplate(sectionsTemplates);
              this.$scope.$apply();
            }, (response) => {
              //Error occured
              this.$log.error(response);
              this.isLoading = false;
            });
          }
        }
      }
    });
  }

  selectTemplate(sectionsTemplate: SectionsTemplate) {
    this.selectedTemplate = sectionsTemplate;
  }

  isSelected(sectionsTemplate: SectionsTemplate) {
    if (!this.selectedTemplate) return false;
    return this.selectedTemplate.id === sectionsTemplate.id;
  }

}
