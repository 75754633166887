import { AccountResponse } from './../../../../../data/account.data';
'use strict';

import { IRootScopeService, IScope } from "angular";
import { WebserverSettings } from "../../../../../data/admin.data";
import RestService from "../../../../../services/rest.service";

require('./admin.webserver.component.scss');

export default class AdminWebserverSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.webserver.component.html');
    this.scope = {
    };
    this.controller = AdminWebserverComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AdminWebserverComponentController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private settings: WebserverSettings;
  public account: AccountResponse;



  constructor($scope: IScope, restService: RestService, private dataService) {
    this.restService = restService;
    this.$scope = $scope;
    this.load();
  }

  resetProxyAuthorization() {
    this.restService.resetProxyAuthorization().then(result => {
      this.settings.amwebAuthorization = result;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }


  /**
   * Load map settings
   */
  load() {
    this.isLoading = true;
    this.restService.loadWebserverSettings().then(settings => {
      this.settings = settings;
      this.account = this.dataService.getAccount();
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });

  }

  /**
   * Save map settings
   */
  save() {
    this.isLoading = true;
    this.restService.saveWebserverSettings(this.settings).then(response => {
      this.settings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
}
