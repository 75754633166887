'use strict';

import {IDocumentService, IRootScopeService, IScope} from "angular";
import * as L from 'leaflet';
import MapService from "../../../../services/map.service";

require('./polygon.map.component.scss');

export default class PolygonMapComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'E'
    this.scope = {
      geoJsonPolygons: "=",
      isReadOnly: "=",
      initPoint: "=",
      colors: "=",
      names: "=",
    }
    this.template = require('./polygon.map.component.html');

    this.controller = PolygonMapComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class PolygonData {
  polygon: any;
  polygonLayer: any;
  color: string;
  name:string;
}

class PolygonMapComponentController {
  public map: any;
  public isReadOnly;
  public geoJsonPolygons: any;
  public initPoint: number[];
  public colors: string[];
  public name: string;
  public names: string[];
  public title: string;
  private defaultPoint = [48.374797820104554, 10.878181457519531];
  private defaultColor = "#001fff";
  private drawnItems: any;
  private multy:boolean;

  private  polygonsData: PolygonData[];

  private listeners = [];

    constructor(
      public $document: IDocumentService,
      public mapService: MapService,
      public $rootScope: IRootScopeService,
      public $scope: IScope) {
      this.multy=false;

      this.initListeners();

      $document.ready(() => {
        this.initPolygonsData();
        this.createMap();
      });

    }

  initPolygonsData(){
  this.polygonsData=[];
  if(!this.geoJsonPolygons){
    this.geoJsonPolygons=[];
  }
  for(let i=0;i<this.geoJsonPolygons.length;i++){
    var polygonData = new PolygonData();
    polygonData.polygon=this.geoJsonPolygons[i];
    polygonData.color=this.getColor(i);
    polygonData.name=this.getName(i);
    polygonData.polygonLayer=this.createPolygon(polygonData);
    this.polygonsData.push(polygonData)
  }
  }

  createPolygon(polygonData: PolygonData){
      if(!polygonData.polygon){
        return null;
      }
    var pointList = this.getPolygonsLatLngList(polygonData.polygon)

    var polygon = new L.polygon(pointList, {color: polygonData.color});
    if(polygonData.name){
      polygon.bindPopup(polygonData.name);
    }
    return polygon;
  }

  private initListeners() {
    this.listeners.push(this.$rootScope.$on('polygon.update', (event, data: any) => {
      this.update();
    }));
    this.listeners.push(this.$rootScope.$on('polygon.focus', (event, data: any) => {
      this.focus(data);
    }));
  }
  focus(data: any){
    var polygonData = this.polygonsData.filter(x=>x.polygon == data);
    if(polygonData.length >0){
      this.map.fitBounds(L.latLngBounds(polygonData[0].polygonLayer.getBounds()), {maxZoom: 13});
    }
  }

  update(){
    this.initPolygonsData();
    this.drawPolygons(this.drawnItems);
  }

  createMap(){

      L.Icon.Default.imagePath = '/img/static';
      this.map = L.map('mapid');
      this.toCenter();

      var currentRouteShape: L.Polyligon= undefined;

      this.initLayers();

      // FeatureGroup is to store editable layers
      var drawnItems = new L.FeatureGroup();
      this.drawnItems=drawnItems;
      this.map.addLayer(drawnItems);

      var drawControl = new L.Control.Draw({
        draw: {
           polygon: {
            tooltip: {
              start: 'Klicken um eine Linie zu zeichnen',
              cont: 'Klicken um die Linie weiter zu zeichnen',
              end: 'Auf den ersten Punkt klicken um die Zeichnung zu beenden.'
            }
          },
          circle: false,
          rectangle: false,
          polyline: false,
          marker: false,
          circlemarker: false
        },
        edit: {
          featureGroup: this.drawnItems
        }
      });
      if (!this.isReadOnly) {
        this.map.addControl(drawControl);
      }

        // Map events
        // Line is created
        this.map.on('draw:created', (layer) => {
          this.polygonCreated(layer, drawnItems);
        });

        // User starts drawing
        this.map.on('draw:drawstart ', () => {
          currentRouteShape = this.polygonDrawStart(drawnItems, currentRouteShape);
        });

        // User edits current shape
        this.map.on('draw:edited ', (e) => {
          this.polygonEdited(e, currentRouteShape, drawnItems);
        });

        // User deletes shape
        this.map.on('draw:deleted', (e) => {
          this.polygonDeleted(e);
        });

       // this.drawPolygon(currentRouteShape, drawnItems);
        this.drawPolygons(drawnItems);

      this.map.invalidateSize();

    }

  private toCenter() {
    if (this.initPoint != null) {
      this.map.setView(this.initPoint, 13);
    } else {
      this.map.setView(this.defaultPoint, 13);
    }
  }

  private polygonDeleted(e) {
    e.layers.eachLayer(layer => {
      for(let i=0;i<this.polygonsData.length; i++){
        if(this.polygonsData[i].polygonLayer==layer) {
          this.polygonsData[i].polygon = null;
          this.polygonsData[i].polygonLayer = null;
          this.geoJsonPolygons[i] = null;
        }
      }

    });

    this.$scope.$applyAsync();
  }

  private polygonEdited(e, currentRouteShape: L.Polyligon, drawnItems: L.FeatureGroup) {
    var layers = e.target;
    layers.eachLayer((layer) => {

      for(let i=0;i<this.polygonsData.length; i++){
        if(layer == this.polygonsData[i].polygonLayer) {
          this.polygonsData[i].polygon = this.convertToPolygon(layer);
          this.polygonsData[i].polygonLayer = layer;
          this.geoJsonPolygons[i] = this.polygonsData[i].polygon;
        }
      }

      this.$scope.$applyAsync();
    });
  }

  private polygonDrawStart(drawnItems: L.FeatureGroup, currentRouteShape: L.Polyline) {
      if(!this.multy){
        drawnItems.clearLayers();
      }
    // Remove current route shape
    if (currentRouteShape !== undefined) {
      drawnItems.removeLayer(currentRouteShape);
      currentRouteShape = undefined;
    }
    return currentRouteShape;
  }

  private polygonCreated(layer, drawnItems: L.FeatureGroup) {

    var polygonData = new PolygonData();
    polygonData.polygon=this.convertToPolygon(layer.layer);
    polygonData.color=this.defaultColor;
    polygonData.name="";
    polygonData.polygonLayer=layer.layer;
    if(!this.multy && this.polygonsData && this.polygonsData.length >0){
      this.polygonsData[0].polygon=this.convertToPolygon(layer.layer);
      this.polygonsData[0].polygonLayer=layer.layer;
      this.geoJsonPolygons[0]=this.polygonsData[0].polygon;
      layer.layer.options.color = this.polygonsData[0].color;
    }else{
      this.polygonsData.push(polygonData)
      this.geoJsonPolygons.push(polygonData.polygon);
      layer.layer.options.color = this.defaultColor;
    }


    drawnItems.addLayer(layer.layer);
    this.$scope.$applyAsync();
  }

  initLayers() {
    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    let layers = this.mapService.getBaseLayers();

    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService
    this.map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });

  }

  drawPolygons(drawnItems: L.FeatureGroup) {
    // Draw polyligon if rout shape exists
    // Polygon
    let allPoints=[]
    drawnItems.clearLayers();
    if (this.polygonsData != null) {
       // this.isReadOnly=true;
      this.polygonsData.forEach(x=>{
        if(x.polygonLayer) {
          drawnItems.addLayer(x.polygonLayer);
          allPoints.push(x.polygonLayer.getBounds())
        }
      })
      }

      if(allPoints.length>0){
        this.map.fitBounds(L.latLngBounds(allPoints), {maxZoom: 13});
      }else{
        this.toCenter()
      }

  }

  private getName(i:number){
    if(this.names != null && this.names.length >i){
      return this.names[i];
    }
    return "";
  }

  private getColor(i:number){
    if(this.colors != null && this.colors.length > i && this.colors[i]){
      return this.colors[i];
    }
    return this.defaultColor;
  }


  private getPolygonsLatLngList(polygon: any){
    var pointList = [];
    if(!polygon){
      return pointList;
    }
    for (var i = 0; i < polygon.coordinates[0].length; i++) {
      var point = polygon.coordinates[0][i];
      var p = new L.LatLng(point[1], point[0]);
      pointList.push(p);
    }
    return pointList;
  }

  private convertToPolygon(layer:any) {
    var coordsArr = layer._latlngs;
    var geoJson= layer.toGeoJSON();
    var coords=[]

    for(var i=0; i<coordsArr.length; i++){
      var LatLng=[coordsArr[i].lng,coordsArr[i].lat]
      coords.push(LatLng);
    }
    if(coordsArr.length>1){
      var LatLng=[coordsArr[0].lng,coordsArr[0].lat]
      coords.push(LatLng);
    }
    geoJson.geometry.coordinates=[coords]
    return geoJson.geometry;
  }

}
