'use strict'


import {UserAccount} from "../../../data/account.data";
import {ILogService, IRootScopeService, IScope} from "angular";
import {AlarmDataSimple} from "../../../data/alarm.data";
import {EmergencyResponse} from "../../../data/emergency.data";
import {RolePrivilege} from "../../../data/privileges.enum";
import PrivilegeService from "../../../services/privilege.service";
import RestService from "../../../services/rest.service";
import {
  ESelectiveAlarmStatus,
  SelectiveAlarm,
  SelectiveAlarmStateChange,
  SelectiveAlarmTableEntry
} from "../../../data/selective.alarms.data";

require('./missions.view.component.scss');

/* @ngInject */
export default class MissionsController {
  public emergencies: EmergencyResponse[];
  public selectiveAlarm: SelectiveAlarmTableEntry[];
  public page: number = 0;
  public pageSelectiveAlarm: number = 0;
  public firstSelectiveAlarm = true;
  public lastSelectiveAlarm = true;
  public first = true;
  public last = true;
  public $log: ILogService;

  public jumpToDate: Date;
  public isDateSearch = false;
  public filter = {
    searchFilter: '',
    reverseOrdering: true
  } as PageFilter;
  public isDecending: boolean = true;

  public isLoading = false;
  public isLoadingSelective = false;
  public account: UserAccount;

  public onlyOwnMissions: boolean = false;

  private listeners = [];

  constructor(private $scope: IScope,
              private $rootScope: IRootScopeService,
              private dataService: any,
              private restService: RestService,
              public privilegeService: PrivilegeService, $log: ILogService) {

    this.jumpToDate = new Date();
    this.jumpToDate.setMilliseconds(0);
    this.jumpToDate.setSeconds(0);

    this.account = this.dataService.getAccount();
    this.initListeners();
    this.load();
    this.loadSelectiveAlarm();

    this.$log = $log;
  }

  updateColumnOrdering() {
    this.filter.reverseOrdering = !this.filter.reverseOrdering;
    this.load();
  }

  refresh() {
    this.page = 0;
    this.load();
    this.pageSelectiveAlarm = 0;
    this.loadSelectiveAlarm();

  }

  searchForDate() {
    if (!this.jumpToDate) return;
    this.isDateSearch = true;
    this.page = 0;
    this.load();
    this.loadSelectiveAlarm();

  }

  resetDateSearch() {
    this.isDateSearch = false;
    this.jumpToDate = new Date();
    this.jumpToDate.setMilliseconds(0);
    this.jumpToDate.setSeconds(0);
    this.page = 0;
    this.load();
    this.loadSelectiveAlarm();

  }

  /**
   Search for specific alarm
   */
  onSearchFilterChanged() {
    this.page = 0; // Reset current page to first page
    this.pageSelectiveAlarm = 0;
    this.load();
    this.loadSelectiveAlarm();

  };

  /**
   Reset the search filter
   */
  resetSearchFilter() {
    this.filter.searchFilter = '';
    this.page = 0; // Reset current page to first page
    this.pageSelectiveAlarm = 0;
    this.load();
    this.loadSelectiveAlarm();
  };

  load() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_List)) {
      return;
    }
    this.account = this.dataService.getAccount();
    this.isLoading = true;

    let ordering = this.filter.reverseOrdering ? 'DESC' : 'ASC';

    this.restService.getAllEmergencies(this.page, 50, this.filter.searchFilter, ordering, this.isDateSearch ? this.jumpToDate.getTime() : 0, this.onlyOwnMissions)
      .then(emergencies => {
        this.page = emergencies.number;
        this.first = emergencies.first;
        this.last = emergencies.last;
        this.emergencies = emergencies.content.filter(emergency => emergency.externalId)
      })
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync()
      });

  }

  updateTimeOrderSelective() {
    this.isDecending = !this.isDecending;
    this.loadSelectiveAlarm();
  }

  loadSelectiveAlarm() {
    this.account = this.dataService.getAccount();
    this.isLoadingSelective = true;


    if (this.privilegeService.has(RolePrivilege.Home_Selective_Alarm)) {
      this.restService.getAllSelectiveAlarms(this.pageSelectiveAlarm, 10, this.filter.searchFilter, this.isDateSearch ? this.jumpToDate.getTime() : 0, this.isDecending).then((response) => {
        this.selectiveAlarm = response.content;
        this.pageSelectiveAlarm = response.number;
        this.firstSelectiveAlarm = response.first;
        this.lastSelectiveAlarm = response.last;
      }).finally(() => {
        this.isLoadingSelective = false;
        this.$scope.$applyAsync();
      })
    }

  }

  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', (event, account) => {
      this.$log.debug('Missions: new.account triggered!');
      this.account = this.dataService.getAccount();
      //reload missions on account switch
      this.load();
      this.loadSelectiveAlarm();
    }));
    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.$log.debug('Missions: delete.account triggered!');
      this.emergencies = undefined;
    }));

    this.listeners.push(this.$rootScope.$on('mission.deleted', () => {
      this.$log.debug('Missions: mission.deleted triggered!');
      this.load();
      this.loadSelectiveAlarm();
    }));

    this.listeners.push(this.$rootScope.$on('new.selective_alarm', (event, selectiveAlarm: SelectiveAlarmTableEntry) => {
      if (selectiveAlarm) {
        this.$log.info('New selective Alarm available!');
        if ((selectiveAlarm.userId === this.account.id) || this.account.admin) {
          let exists = this.selectiveAlarm.some(existing => existing.id === selectiveAlarm.id);
          if (!exists) {
            this.selectiveAlarm.unshift(selectiveAlarm);
            this.$scope.$applyAsync();
          }
        }
      }
    }))
    this.listeners.push(this.$rootScope.$on('update.selective_alarm', (event, selectiveAlarmState: SelectiveAlarmStateChange) => {
      if (selectiveAlarmState && selectiveAlarmState.status === ESelectiveAlarmStatus.CLOSED) {
        this.$log.info('Closed selective Alarm!');
        this.pageSelectiveAlarm = 0;
        this.loadSelectiveAlarm();
      }
    }))

    //Wait for new alarms
    this.listeners.push(this.$rootScope.$on('new.alarm', (event, alarm: AlarmDataSimple) => {

      if (alarm.externalId === undefined || alarm.vehicles === undefined) {
        // probably not a emergency and not relevant for this list
        return;
      }

      let hasInList = this.emergencies.filter(emergency => emergency.externalId === alarm.externalId).length > 0;

      if (!hasInList && this.page === 0) {

        if (this.filter.searchFilter) {
          // Don't add emergency if filter is active
          return;
        }

        var myMission = alarm.userId === this.account.id || alarm.userId === "" ? true : false;

        // Convert to emergency
        let newAlarm = {
          externalId: alarm.externalId,
          keyword: alarm.keyword,
          location_dest: alarm.locationDest,
          timestamp: alarm.time,
          vehicles: alarm.vehicles,
          color: 'red',
          myMission
        } as EmergencyResponse;
        this.emergencies.unshift(newAlarm);
      }
    }));

    // Wait for deletion of an alarm
    this.listeners.push(this.$rootScope.$on('single.alarm.deleted', (event, alarm: AlarmDataSimple) => {
      this.load();
      this.loadSelectiveAlarm();

    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  previousPage() {
    if (!this.first) {
      this.page--;
      this.load();
    }
  }

  previousPageSelectiveAlarm() {
    if (!this.firstSelectiveAlarm) {
      this.pageSelectiveAlarm--;
      this.loadSelectiveAlarm();
    }
  }

  nextPage() {
    if (!this.last) {
      this.page++;
      this.load();
    }
  }

  nextPageSelectiveAlarm() {
    if (!this.lastSelectiveAlarm) {
      this.pageSelectiveAlarm++;
      this.loadSelectiveAlarm();
    }
  }

  toggleOwnMissions() {
    this.load();
  }
}

interface PageFilter {
  searchFilter: string,
  orderByField: string,
  reverseOrdering: boolean
}
